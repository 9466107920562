import { DeviceType } from './sound-profile.model';

export interface DeviceSettings {
    deviceType: DeviceType;
    lowFrequencyThreshold: number;
    frequencyTolerance: number;
    consistencyTolerance: number;
    lowFrequencyEnergyRatio: number;
    amplitudeThreshold: number;
    detectionThreshold: number;
    requiredMatches: number;
    minimumScore: number;
    sensitivity: number;
    soundStabilityDuration: number;
}

// Default settings for each device type
export const DEFAULT_DEVICE_SETTINGS: Record<DeviceType, DeviceSettings> = {
    'open mic': {
        deviceType: 'open mic',
        lowFrequencyThreshold: 400,
        frequencyTolerance: 300,
        consistencyTolerance: 0.5,
        lowFrequencyEnergyRatio: 0.5,
        amplitudeThreshold: 0.15,
        detectionThreshold: 0.7,
        requiredMatches: 1,
        minimumScore: 70,
        sensitivity: 0.5,
        soundStabilityDuration: 500
    },
    'desktop + microphone': {
        deviceType: 'desktop + microphone',
        lowFrequencyThreshold: 400,
        frequencyTolerance: 300,
        consistencyTolerance: 0.3,
        lowFrequencyEnergyRatio: 0.2,
        amplitudeThreshold: 0.05,
        detectionThreshold: 0.6,
        requiredMatches: 3,
        minimumScore: 0.6,
        sensitivity: 0.5,
        soundStabilityDuration: 500
    },
    'desktop + webcam': {
        deviceType: 'desktop + webcam',
        lowFrequencyThreshold: 400,
        frequencyTolerance: 300,
        consistencyTolerance: 0.3,
        lowFrequencyEnergyRatio: 0.2,
        amplitudeThreshold: 0.05,
        detectionThreshold: 0.6,
        requiredMatches: 3,
        minimumScore: 0.6,
        sensitivity: 0.5,
        soundStabilityDuration: 500
    },
    'mobile': {
        deviceType: 'mobile',
        lowFrequencyThreshold: 400,
        frequencyTolerance: 300,
        consistencyTolerance: 0.3,
        lowFrequencyEnergyRatio: 0.2,
        amplitudeThreshold: 0.05,
        detectionThreshold: 0.6,
        requiredMatches: 3,
        minimumScore: 0.6,
        sensitivity: 0.5,
        soundStabilityDuration: 500
    },
    'mobile + headphone': {
        deviceType: 'mobile + headphone',
        lowFrequencyThreshold: 400,
        frequencyTolerance: 300,
        consistencyTolerance: 0.3,
        lowFrequencyEnergyRatio: 0.2,
        amplitudeThreshold: 0.05,
        detectionThreshold: 0.6,
        requiredMatches: 3,
        minimumScore: 0.6,
        sensitivity: 0.5,
        soundStabilityDuration: 500
    }
}; 