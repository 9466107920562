<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xl mx-auto">
    <!-- Add after the title, before the Audio Library Controls -->
    <ul class="menu menu-horizontal bg-base-200 rounded-box mb-8 w-full flex">
        <div class="flex">
            <li><a (click)="scrollToElement('library-controls')"
                    [class.active]="activeSection() === 'library-controls'">Library Controls</a></li>
            <li><a (click)="scrollToElement('device-settings')"
                    [class.active]="activeSection() === 'device-settings'">Device Settings</a></li>
            <li><a (click)="scrollToElement('device-profiles')"
                    [class.active]="activeSection() === 'device-profiles'">Device Profiles</a></li>
            <li><a (click)="scrollToElement('sound-patterns')" 
                    [class.active]="activeSection() === 'sound-patterns'">Sound Patterns</a></li>
        </div>
        <div class="flex-1"></div>
        <li>
            <a routerLink="/admin/settings" class="flex items-center">
                Settings
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-right ml-2" width="20"
                    height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l14 0"></path>
                    <path d="M13 18l6 -6"></path>
                    <path d="M13 6l6 6"></path>
                </svg>
            </a>
        </li>
    </ul>

    <!-- Audio Library Controls -->
    <div #libraryControls id="library-controls" class="mb-8 flex justify-between items-center">
        <h2 class="text-2xl font-bold text-secondary">Audio Library</h2>
        <div class="flex gap-2 items-center">
            <details class="dropdown dropdown-end">
                <summary class="btn btn-ghost">
                    <span class="mr-2">Utilities</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M4 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                        <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                        <path d="M20 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                    </svg>
                </summary>
                <ul class="menu dropdown-content bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
                    <li>
                        <button (click)="exportAllProfiles()" [disabled]="isExporting()">
                            {{ isExporting() ? 'Exporting...' : 'Export All Profiles' }}
                        </button>
                    </li>
                    <li>
                        <button (click)="exportDeviceSettings()" [disabled]="isExporting()">
                            {{ isExporting() ? 'Exporting...' : 'Export Device Settings' }}
                        </button>
                    </li>
                </ul>
            </details>
            <button class="btn btn-ghost" (click)="toggleVersionsDrawer()">
                <span class="mr-2">Audio Library Versions</span>
                <span class="badge badge-sm badge-neutral" *ngIf="currentVersionUid()">
                    v{{ currentVersionUid() }}
                </span>
            </button>
        </div>
    </div>

    <!-- Two-Column Layout -->
    <div #deviceSettings id="device-settings" class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
        <!-- Column 1: Webcam Module -->
        <div class="card bg-base-100 lg:shadow-xl">
            <div class="card-body">
                <h3 class="card-title text-lg mb-4">Webcam Module</h3>
                <app-webcam [detectionMode]="false" [demoMode]="true" [debugMode]="true"
                    [suppressConsultationCard]="true"
                    class="transition-all duration-1000 ease-in-out">
                </app-webcam>
                
                <!-- Moved Real-time Profile Similarity here -->
                <div class="mt-4">
                    <h3 class="card-title text-lg mb-4">Real-time Profile Similarity</h3>
                    <app-similarity-score [deviceType]="selectedDeviceType()"></app-similarity-score>
                </div>
            </div>
        </div>

        <!-- Column 2: Device Settings -->
        <div class="card bg-base-100">
            <div class="card-body">
                <h3 class="card-title text-lg mb-4">Test Device Types</h3>

                <!-- Device Type Selection -->
                <div class="form-control w-full">
                    <select class="select select-bordered select-lg w-full" 
                        [ngModel]="selectedDeviceType()"
                        (ngModelChange)="onDeviceTypeChange($event)">
                        <option disabled [value]="null">Select a device type to test</option>
                        <option *ngFor="let type of deviceTypes" [value]="type">
                            {{ type }}
                        </option>
                    </select>
                </div>

                <!-- Settings Content -->
                <div *ngIf="selectedDeviceType()">
                    <div class="divider"></div>

                    <!-- Device Detection Settings (Now Expanded by Default) -->
                    <div class="bg-base-200 p-4 rounded-lg">
                        <div class="text-base font-medium mb-4">
                            Device Detection Settings
                        </div>
                        
                        <!-- Audio Level Indicators -->
                     

                        <!-- Main Sensitivity Control -->
                        <div class="form-control w-full mb-6">
                            <label class="label">
                                <span class="text-sm font-medium">Input Level & Sensitivity</span>
                                <span class="label-text-alt text-xs text-base-content/60">
                                    {{ calculateSliderValue(currentDeviceSettings()?.lowFrequencyEnergyRatio ?? 0.4) | number:'1.0-0' }}%
                                </span>
                            </label>
                            <div class="relative">
                                
                                    <!-- Input Level Progress -->
                                    <progress 
                                        class="progress progress-primary w-full transition-all" 
                                        [style.transition-duration.ms]="soundStabilityDuration()"
                                        [value]="currentAudioLevel()" 
                                        max="100">
                                    </progress>
                                  
                                
                                <input type="range" 
                                    class="range range-secondary range-xs" 
                                    [ngModel]="calculateSliderValue(currentDeviceSettings()?.lowFrequencyEnergyRatio ?? 0.4)"
                                    (ngModelChange)="onSettingChange('lowFrequencyEnergyRatio', $event)"
                                    min="0" 
                                    max="100" 
                                    step="1" />
                                
                                <!-- Step markers -->
                                <div class="w-full flex justify-between text-xs px-2 h-6">
                                    <div class="flex flex-col items-center">
                                        <div class="w-[1px] h-2 bg-base-content/30"></div>
                                        <span class="text-[10px] mt-1">0%</span>
                                    </div>
                                    <div class="flex flex-col items-center">
                                        <div class="w-[1px] h-2 bg-base-content/30"></div>
                                        <span class="text-[10px] mt-1">20%</span>
                                    </div>
                                    <div class="flex flex-col items-center">
                                        <div class="w-[1px] h-2 bg-base-content/30"></div>
                                        <span class="text-[10px] mt-1">40%</span>
                                    </div>
                                    <div class="flex flex-col items-center">
                                        <div class="w-[1px] h-2 bg-base-content/30"></div>
                                        <span class="text-[10px] mt-1">60%</span>
                                    </div>
                                    <div class="flex flex-col items-center">
                                        <div class="w-[1px] h-2 bg-base-content/30"></div>
                                        <span class="text-[10px] mt-1">80%</span>
                                    </div>
                                    <div class="flex flex-col items-center">
                                        <div class="w-[1px] h-2 bg-base-content/30"></div>
                                        <span class="text-[10px] mt-1">100%</span>
                                    </div>
                                </div>
                            </div>
                            <div class="label">
                                <span class="label-text-alt">Less Sensitive</span>
                                <span class="label-text-alt">More Sensitive</span>
                            </div>
                        </div>

                        <!-- Sound Stability -->
                        <div class="form-control w-full mb-6">
                            <label class="label">
                                <span class="label-text font-medium">Input Level Decay Rate</span>
                                <span class="label-text-alt">{{ formatStabilityDuration(soundStabilityDuration()) }}</span>
                            </label>
                            <input 
                                type="range" 
                                class="range range-secondary" 
                                min="0"
                                max="100"
                                [value]="calculateStabilitySliderValue(soundStabilityDuration())"
                                (input)="onSoundStabilityChange($any($event.target).value)"
                                step="1"
                            />
                            <div class="label">
                                <span class="label-text-alt">Quick Decay (200ms)</span>
                                <span class="label-text-alt">Slow Decay (5s)</span>
                            </div>
                            <span class="text-xs text-base-content/70">
                                Controls how quickly the input level indicator drops after detecting sound
                            </span>
                        </div>

                        <!-- Advanced Settings with Explanations -->
                        <div class="space-y-4">
                            <!-- Minimum Sound Frequency -->
                            <div class="form-control w-full mb-4">
                                <label class="label">
                                    <span class="label-text font-medium">Minimum Sound Frequency</span>
                                    <span class="label-text-alt text-xs text-base-content/60">Hz</span>
                                </label>
                                <input type="number" 
                                    class="input input-bordered w-full"
                                    [ngModel]="currentDeviceSettings()?.lowFrequencyThreshold"
                                    (ngModelChange)="onLowFrequencyThresholdChange($event)" 
                                    min="0" />
                                <label class="label">
                                    <span class="label-text-alt text-base-content/70">
                                        The lowest frequency that will trigger detection. Lower values (e.g., 100Hz) detect deeper sounds, while higher values (e.g., 500Hz) focus on higher-pitched sounds.
                                    </span>
                                </label>
                            </div>

                            <!-- Frequency Tolerance -->
                            <div class="form-control w-full mb-4">
                                <label class="label">
                                    <span class="label-text font-medium">Frequency Tolerance</span>
                                    <span class="label-text-alt text-xs text-base-content/60">Hz</span>
                                </label>
                                <input type="number" 
                                    class="input input-bordered w-full"
                                    [ngModel]="currentDeviceSettings()?.frequencyTolerance"
                                    (ngModelChange)="onFrequencyToleranceChange($event)" 
                                    min="0" />
                                <label class="label">
                                    <span class="label-text-alt text-base-content/70">
                                        How much variation in frequency is allowed. A higher tolerance (e.g., 50Hz) is more forgiving of slight variations in sound, while lower values (e.g., 10Hz) require more precise matching.
                                    </span>
                                </label>
                            </div>
                        </div>

                        <!-- Reset Button -->
                        <div class="mt-6 flex justify-end">
                            <button class="btn btn-outline btn-warning"
                                (click)="resetDeviceSettings(selectedDeviceType())"
                                [disabled]="!hasChangedFromDefault()">
                                Reset to Default Settings
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Sound Profiles Tabs -->
    <div #soundPatterns id="sound-patterns" class="card bg-base-100 lg:shadow-xl">
        <div class="card-body">
            <h3 class="card-title text-lg mb-4">Sound Pattern Recording</h3>

            <div role="tablist" class="tabs tabs-boxed bg-base-200 inline-flex">
                <button role="tab" class="tab tab-lg transition-all duration-200"
                    [class.tab-active]="activeTab() === 'positive'" 
                    [class.btn-secondary]="activeTab() === 'positive'"
                    (click)="setActiveTab('positive')">
                    <span class="flex items-center gap-2">
                        <span>💨</span>
                        <span>Positive Pattern</span>
                    </span>
                </button>
                <button role="tab" class="tab tab-lg transition-all duration-200"
                    [class.tab-active]="activeTab() === 'negative'" 
                    [class.btn-secondary]="activeTab() === 'negative'"
                    (click)="setActiveTab('negative')">
                    <span class="flex items-center gap-2">
                        <span>❌</span>
                        <span>Negative Patterns</span>
                    </span>
                </button>
            </div>

            <!-- Positive Tab Content -->
            <div [class.hidden]="activeTab() !== 'positive'" class="mt-4">
                <div class="card bg-base-100 mb-8 px-1 lg:px-4">
                    <div class="card-body px-1 lg:px-4">
                        <h3 class="card-title text-lg font-semibold">Positive Blow Pattern Calibration:</h3>
                        <p class="mb-4">Create a frequency domain profile of blow audio that will be used for FFT-based
                            pattern
                            matching.</p>

                        <div class="grid grid-cols-1 gap-6 mb-8">
                            <!-- Breath Pattern Card -->
                            <div class="card bg-base-100">
                                <div class="card-body px-1 lg:px-4">
                                    <h4 class="card-title text-base">Blow Pattern</h4>
                                    <button class="btn btn-primary w-fit relative" (click)="startRecording('blow')"
                                        [disabled]="isRecording() || isTesting() || breathProfiles().length >= MAX_PROFILES_PER_TYPE">
                                        💨 Record Blow
                                        <span *ngIf="isRecording() && !isTesting() && currentRecordingType() === 'blow'"
                                            class="absolute -top-1 -right-1 flex h-3 w-3">
                                            <span
                                                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                        </span>
                                    </button>
                                    <div class="overflow-x-auto">
                                        <table class="table w-full">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Created</th>
                                                    <th>Created By</th>
                                                    <th>Device Type</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let profile of breathProfiles(); trackBy: trackByFn">
                                                    <td>{{ profile.id }}</td>
                                                    <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                                    <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                                    <td>
                                                        <select class="select select-bordered w-full max-w-xs"
                                                            [(ngModel)]="profile.deviceType"
                                                            (change)="updateDeviceType(profile)">
                                                            <option [ngValue]="null">Not specified</option>
                                                            <option *ngFor="let type of deviceTypes" [value]="type">{{
                                                                type }}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-sm btn-ghost" (click)="playAudio(profile)"
                                                            [disabled]="isPlaying() === profile.id">
                                                            @if (isPlaying() !== profile.id) {
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-play" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M7 4v16l13 -8z"></path>
                                                                </svg>
                                                            } @else {
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-pause" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M6 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
                                                                    <path d="M14 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
                                                                </svg>
                                                            }
                                                        </button>
                                                        <button class="btn btn-sm btn-ghost" (click)="confirmDelete(profile)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M4 7l16 0"></path>
                                                                <path d="M10 11l0 6"></path>
                                                                <path d="M14 11l0 6"></path>
                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                            </svg>
                                                        </button>
                                                        <button class="btn btn-sm btn-ghost" (click)="showProfileDetails(profile)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Negative Tab Content -->
            <div [class.hidden]="activeTab() !== 'negative'" class="mt-4">
                <h4 class="text-lg font-semibold mb-4">Negative Pattern Calibration</h4>
                <p class="mb-6">Create frequency domain profiles of common sounds that should not trigger the device.
                </p>

                <!-- Laugh Section -->
                <div class="mb-8">
                    <h5 class="text-md font-medium mb-2">Laugh Pattern</h5>
                    <div class="card">
                        <div class="card-body px-1">
                            <button class="btn btn-primary w-fit relative" (click)="startRecording('laugh')"
                                [disabled]="isRecording() || laughProfiles().length >= MAX_PROFILES_PER_TYPE">
                                😄 Record Laugh
                                <span *ngIf="isRecording() && currentRecordingType() === 'laugh'"
                                    class="absolute -top-1 -right-1 flex h-3 w-3">
                                    <span
                                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                </span>
                            </button>
                            <div class="overflow-x-auto">
                                <table class="table w-full">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Created</th>
                                            <th>Created By</th>
                                            <th>Device Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let profile of laughProfiles(); trackBy: trackByFn">
                                            <td>{{ profile.id }}</td>
                                            <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                            <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                            <td>
                                                <select [(ngModel)]="profile.deviceType"
                                                    (change)="updateDeviceType(profile)"
                                                    class="select select-bordered w-full max-w-xs">
                                                    <option [ngValue]="null">Not specified</option>
                                                    <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <button class="btn btn-sm btn-ghost" (click)="playAudio(profile)"
                                                    [disabled]="isPlaying() === profile.id">
                                                    @if (isPlaying() !== profile.id) {
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-play" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M7 4v16l13 -8z"></path>
                                                        </svg>
                                                    } @else {
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-pause" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M6 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
                                                            <path d="M14 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
                                                        </svg>
                                                    }
                                                </button>
                                                <button class="btn btn-sm btn-ghost" (click)="confirmDelete(profile)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 7l16 0"></path>
                                                        <path d="M10 11l0 6"></path>
                                                        <path d="M14 11l0 6"></path>
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                    </svg>
                                                </button>
                                                <button class="btn btn-sm btn-ghost" (click)="showProfileDetails(profile)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Hello Section -->
                <div class="mb-8">
                    <h5 class="text-md font-medium mb-2">Hello Pattern</h5>
                    <div class="card">
                        <div class="card-body px-1">
                            <button class="btn btn-primary w-fit relative" (click)="startRecording('hello')"
                                [disabled]="isRecording() || helloProfiles().length >= MAX_PROFILES_PER_TYPE">
                                👋 Record Hello
                                <span *ngIf="isRecording() && currentRecordingType() === 'hello'"
                                    class="absolute -top-1 -right-1 flex h-3 w-3">
                                    <span
                                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                </span>
                            </button>
                            <div class="overflow-x-auto">
                                <table class="table w-full">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Created</th>
                                            <th>Created By</th>
                                            <th>Device Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let profile of helloProfiles(); trackBy: trackByFn">
                                            <td>{{ profile.id }}</td>
                                            <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                            <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                            <td>
                                                <select class="select select-bordered w-full max-w-xs"
                                                    [(ngModel)]="profile.deviceType"
                                                    (change)="updateDeviceType(profile)">
                                                    <option [ngValue]="null">Not specified</option>
                                                    <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <button class="btn btn-sm btn-ghost" (click)="playAudio(profile)"
                                                    [disabled]="isPlaying() === profile.id">
                                                    @if (isPlaying() !== profile.id) {
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-play" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M7 4v16l13 -8z"></path>
                                                        </svg>
                                                    } @else {
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-pause" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M6 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
                                                            <path d="M14 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
                                                        </svg>
                                                    }
                                                </button>
                                                <button class="btn btn-sm btn-ghost" (click)="confirmDelete(profile)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 7l16 0"></path>
                                                        <path d="M10 11l0 6"></path>
                                                        <path d="M14 11l0 6"></path>
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                    </svg>
                                                </button>
                                                <button class="btn btn-sm btn-ghost" (click)="showProfileDetails(profile)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Clap Section -->
                <div class="mb-8">
                    <h5 class="text-md font-medium mb-2">Clap Pattern</h5>
                    <div class="card">
                        <div class="card-body px-1">
                            <button class="btn btn-primary w-fit relative" (click)="startRecording('clap')"
                                [disabled]="isRecording() || clapProfiles().length >= MAX_PROFILES_PER_TYPE">
                                👏 Record Clap
                                <span *ngIf="isRecording() && currentRecordingType() === 'clap'"
                                    class="absolute -top-1 -right-1 flex h-3 w-3">
                                    <span
                                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                </span>
                            </button>
                            <div class="overflow-x-auto">
                                <table class="table w-full">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Created</th>
                                            <th>Created By</th>
                                            <th>Device Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let profile of clapProfiles(); trackBy: trackByFn">
                                            <td>{{ profile.id }}</td>
                                            <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                            <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                            <td>
                                                <select class="select select-bordered w-full max-w-xs"
                                                    [(ngModel)]="profile.deviceType"
                                                    (change)="updateDeviceType(profile)">
                                                    <option [ngValue]="null">Not specified</option>
                                                    <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <button class="btn btn-sm btn-ghost" (click)="playAudio(profile)"
                                                    [disabled]="isPlaying() === profile.id">
                                                    @if (isPlaying() !== profile.id) {
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-play" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M7 4v16l13 -8z"></path>
                                                        </svg>
                                                    } @else {
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-pause" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M6 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
                                                            <path d="M14 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
                                                        </svg>
                                                    }
                                                </button>
                                                <button class="btn btn-sm btn-ghost" (click)="confirmDelete(profile)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 7l16 0"></path>
                                                        <path d="M10 11l0 6"></path>
                                                        <path d="M14 11l0 6"></path>
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                    </svg>
                                                </button>
                                                <button class="btn btn-sm btn-ghost" (click)="showProfileDetails(profile)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add this at the end of your component template -->
    <dialog id="profile_details_modal" class="modal">
        <div class="modal-box">
            <div class="modal-action">
                <form method="dialog">
                    <button class="btn">Close</button>
                </form>
            </div>
            <h3 class="font-bold text-lg mb-4">Profile Details</h3>
            @if (selectedProfile) {
            <div class="overflow-x-auto">
                <table class="table w-full">
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Add Profile ID as the first row -->
                        <tr>
                            <td>Profile ID</td>
                            <td>{{ selectedProfile.id }}</td>
                        </tr>
                        @for (entry of selectedProfileEntries; track entry.key) {
                        <tr>
                            <td>{{ entry.key }}</td>
                            <td>
                                @if (isArray(entry.value)) {
                                <div class="flex items-center">
                                    <span class="mr-2">{{ formatValue(entry.value, entry.key) }}</span>
                                    <button class="btn btn-xs" (click)="toggleArrayExpansion(entry.key)">
                                        {{ expandedArrays[entry.key] ? 'Collapse' : 'Expand' }} Data
                                    </button>
                                </div>
                                } @else {
                                {{ formatValue(entry.value, entry.key) }}
                                }
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            }

        </div>
    </dialog>

    <!-- Add this near the end of your file, before the closing section tag -->
    <div class="drawer drawer-end">
        <input id="audio-library-versions-drawer" type="checkbox" class="drawer-toggle" />
        <div class="drawer-content">
            <!-- Page content here -->
        </div> 
        <div class="drawer-side z-[1000]">
            <label for="audio-library-versions-drawer" aria-label="close sidebar" class="drawer-overlay"></label>
            <div class="min-h-full bg-base-200 w-80 sm:w-96">
                <app-audio-library-versions 
                  [currentVersionUid]="currentVersionUid()"
                ></app-audio-library-versions>
            </div>
        </div>
    </div>

</section>