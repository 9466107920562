<div class="h-full flex flex-col justify-end">

<div class="h-full flex flex-col justify-end">
  <div class="mode-overlay">
    <app-bubble-overlay 
      *ngIf="selectedMode === 'Bubbles'" 
      [isAnimating]="isAnimating" 
      >
    </app-bubble-overlay>
    
    <app-music-overlay 
      *ngIf="selectedMode === 'Music'" 
      [isAnimating]="isAnimating" 
      [audioLevel]="audioLevel">
    </app-music-overlay>
    
    <app-pinwheel-overlay 
      *ngIf="selectedMode === 'Pinwheel'" 
      [isAnimating]="isAnimating" 
      [audioLevel]="audioLevel"
      [isWebcamActive]="isWebcamActive"
      [blowDetected]="blowDetected">
    </app-pinwheel-overlay>
    
    <!-- Diagnostics Component -->  
  </div>
  <!-- Other content -->  
</div>