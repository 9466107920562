import { Injectable, NgZone, inject } from '@angular/core';
import { WebcamComponent } from '@components/webcam/webcam.component';

// Debug mode - when false, disables all logging
const DEBUG_MODE = false;

// Twinkle Constants

// Minimum number of star particles to always keep in the animation
const MIN_PARTICLES = 50;

// Maximum number of star particles allowed in the animation
const MAX_PARTICLES = 250;

// How long each star particle lives before being replaced (in milliseconds)
const PARTICLE_LIFETIME = 2000;

// Default color for the stars (pure white)
const DEFAULT_STAR_COLOR = '#FFFFFF';

// Star Rendering Configuration
// Controls the base size and scaling of star particles
// - BASE_STAR_SIZE: Defines the initial pixel diameter of the star
// - STAR_SIZE_SCALE_FACTOR: Multiplier for dynamic scaling during animation
const BASE_STAR_SIZE = 100;  // Base diameter in pixels
const STAR_SIZE_SCALE_FACTOR = 1.0;  // Additional scaling factor
const DEFAULT_STAR_RADIUS = BASE_STAR_SIZE / 2;  // Radius is half the diameter
const DEFAULT_STAR_BLUR = 0.5;

// Different star shapes to randomly choose from (number of points in the star)
const STAR_VERTEX_NUMS = [4, 5, 6]; // Can create 4, 5, or 6-pointed stars

// Opacity of the star's glow effect (closer to 1 means brighter, more intense glow)
const STAR_GLOW_OPACITY = 0.9;

interface TwinkleConfig {
  starColor: string;
  starRadius: number;
  starBlur: number;
}

@Injectable({
  providedIn: 'root'
})
export class TwinkleService {
  private canvas: HTMLCanvasElement | null = null;
  private ctx: CanvasRenderingContext2D | null = null;
  private twinkle: Twinkle | null = null;
  private animationFrameId: number | null = null;
  private isActive = false;

  constructor(private ngZone: NgZone) {}

  init(canvas: HTMLCanvasElement, centerX: number, centerY: number, config: TwinkleConfig = {
    starColor: DEFAULT_STAR_COLOR,
    starRadius: DEFAULT_STAR_RADIUS,
    starBlur: DEFAULT_STAR_BLUR
  }) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.isActive = true;

    if (!this.ctx) {
      if (DEBUG_MODE) console.error('Unable to get 2D context');
      return;
    }

    this.twinkle = new Twinkle(config.starColor, config.starRadius, config.starBlur, canvas.width, canvas.height, centerX, centerY);

    this.ngZone.runOutsideAngular(() => {
      this.update();
    });
  }

  private update() {
    if (this.isActive && this.ctx && this.canvas && this.twinkle) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.twinkle.render(this.ctx);
      this.animationFrameId = requestAnimationFrame(() => this.update());
    }
  }

  updateRotationSpeed(speed: number, maxParticleSpeedMultiplier: number) {
    if (this.twinkle) {
      this.twinkle.updateRotationSpeed(speed, maxParticleSpeedMultiplier);
    }
  }

  destroy() {
    this.isActive = false;
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    
    if (this.ctx && this.canvas) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }
}

class Twinkle {
  private symbols: Symbols;
  private particles: Particle[] = [];
  private pool: Particle[] = [];
  private centerX: number;
  private centerY: number;
  private readonly MIN_PARTICLES = MIN_PARTICLES;
  private readonly MAX_PARTICLES = MAX_PARTICLES;
  private readonly PARTICLE_LIFETIME = PARTICLE_LIFETIME;
  private rotationSpeed: number = 1;
  private maxParticleSpeedMultiplier: number = 1;

  constructor(starColor: string, starRadius: number, starBlur: number, canvasWidth: number, canvasHeight: number, centerX: number, centerY: number) {
    this.symbols = new Symbols(starColor, starRadius, starBlur);
    this.centerX = centerX;
    this.centerY = centerY;
    this.createInitialParticles();
  }

  private createInitialParticles() {
    for (let i = 0; i < this.MIN_PARTICLES; i++) {
      this.createParticle();
    }
  }

  private createParticle() {
    const p = this.pool.length ? this.pool.pop()! : new Particle(this.symbols);
    const angle = Math.random() * Math.PI * 2;
    const speed = (1 + Math.random() * 2) * this.maxParticleSpeedMultiplier;
    p.init(
      this.centerX,
      this.centerY,
      Math.cos(angle) * speed,
      Math.sin(angle) * speed,
      0.5 + Math.random() * 0.5,
      Math.random() * Math.PI * 2,
      Date.now()
    );
    this.particles.push(p);
  }

  updateRotationSpeed(speed: number, maxParticleSpeedMultiplier: number) {
    this.rotationSpeed = speed;
    this.maxParticleSpeedMultiplier = maxParticleSpeedMultiplier;
  }

  render(ctx: CanvasRenderingContext2D) {
    const currentTime = Date.now();
    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;

    // Maintain exact particle counts based on speed
    const targetParticles = this.rotationSpeed <= 0.5 ? 
      this.MAX_PARTICLES :  // Fast rotation: 50 particles
      this.MIN_PARTICLES;   // Default rotation: 5 particles

    // Adjust particle count to match target exactly
    while (this.particles.length < targetParticles) {
      this.createParticle();
    }
    while (this.particles.length > targetParticles) {
      this.pool.push(this.particles.pop()!);
    }

    // Update and render existing particles
    for (let i = this.particles.length - 1; i >= 0; i--) {
      const p = this.particles[i];
      p.update(this.rotationSpeed);
      p.render(ctx);

      if (p.isOutOfBounds(canvasWidth, canvasHeight) || 
          p.scale <= 0 || 
          (currentTime - p.creationTime > this.PARTICLE_LIFETIME)) {
        this.pool.push(this.particles.splice(i, 1)[0]);
        this.createParticle(); // Immediately replace removed particle
      }
    }
  }
}

class Particle {
  x: number = 0;
  y: number = 0;
  vx: number = 0;
  vy: number = 0;
  scale: number = 0;
  angle: number = 0;
  creationTime: number = 0;
  private symbols: Symbols;
  private static lastLogTime: number = 0;
  private static sizeLog: number[] = [];

  constructor(symbols: Symbols) {
    this.symbols = symbols;
  }

  init(x: number, y: number, vx: number, vy: number, scale: number, angle: number, creationTime: number) {
    this.x = x;
    this.y = y;
    this.vx = vx;
    this.vy = vy;
    this.scale = scale;
    this.angle = angle;
    this.creationTime = creationTime;
  }

  update(rotationSpeed: number) {
    // Drastically reduce base speed (5x slower for default state)
    // Base speed is 0.2x at default rotation, 4x at max speed
    const speedMultiplier = rotationSpeed <= 0.5 ? 4.0 : 0.2;
    
    this.x += this.vx * speedMultiplier;
    this.y += this.vy * speedMultiplier;
    this.scale -= 0.000;
    this.angle += 0.05;
  }

  render(ctx: CanvasRenderingContext2D) {
    const symbol = this.symbols[Math.floor(Math.random() * this.symbols.length)];
    
    // More predictable size calculation
    const size = this.symbols.size * this.scale;
    
    // Log size periodically
    const currentTime = Date.now();
    if (DEBUG_MODE && currentTime - Particle.lastLogTime > 2000) {
      Particle.sizeLog.push(size);
      
      // Calculate average size
      const avgSize = Particle.sizeLog.reduce((a, b) => a + b, 0) / Particle.sizeLog.length;
      
      console.log('Twinkle Star Size Trace:', {
        symbolBaseSize: this.symbols.size,
        particleScale: this.scale,
        currentSize: size,
        averageSize: avgSize,
        sampleCount: Particle.sizeLog.length
      });

      // Reset log after 2 seconds
      Particle.lastLogTime = currentTime;
      
      // Optional: Limit log size to prevent memory growth
      if (Particle.sizeLog.length > 100) {
        Particle.sizeLog.shift();
      }
    }
    
    ctx.save();
    ctx.translate(this.x, this.y);
    ctx.rotate(this.angle);
    ctx.drawImage(symbol, -size / 2, -size / 2, size, size);
    ctx.restore();
  }

  isOutOfBounds(width: number, height: number): boolean {
    return (
      this.x + this.symbols.size * 0.5 < 0 ||
      this.x - this.symbols.size * 0.5 > width ||
      this.y + this.symbols.size * 0.5 < 0 ||
      this.y - this.symbols.size * 0.5 > height
    );
  }
}

class Symbols extends Array<HTMLCanvasElement> {
  size: number;
  color: string[];

  constructor(color: string, radius: number, blur: number) {
    super();
    this.color = this.parseColor(color);
    
    // Explicitly set size to BASE_STAR_SIZE, ignoring input radius
    this.size = BASE_STAR_SIZE;
    
    if (DEBUG_MODE) {
      console.log('🌟 FORCE SIZE OVERRIDE:', {
        inputRadius: radius,
        inputBlur: blur,
        forcedSize: this.size,
        baseStarSize: BASE_STAR_SIZE
      });
    }

    this.initSymbols(radius, blur);
  }

  private initSymbols(radius: number, blur: number) {
    for (const vertexNum of STAR_VERTEX_NUMS) {
      this.push(this.createSymbol(vertexNum, radius, blur));
    }
  }

  private createSymbol(vertexNum: number, radius: number, blur: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const size = this.size;
    canvas.width = canvas.height = size;
    const ctx = canvas.getContext('2d')!;
    
    if (DEBUG_MODE) {
      console.log('🌠 Star Particle FORCED Dimensions:', {
        canvasWidth: canvas.width,
        canvasHeight: canvas.height,
        forcedSize: size,
        baseStarSize: BASE_STAR_SIZE
      });
    }
    
    ctx.fillStyle = DEFAULT_STAR_COLOR;
    
    ctx.shadowBlur = blur;
    ctx.shadowColor = `rgba(255, 255, 255, ${STAR_GLOW_OPACITY})`;
    
    ctx.beginPath();
    for (let i = 0; i < vertexNum * 2; i++) {
      const innerPointScale = Math.max(0.1, radius / 100);
      const r = i % 2 ? radius * innerPointScale : radius;
      const a = (Math.PI * 2 * i) / (vertexNum * 2);
      ctx[i === 0 ? 'moveTo' : 'lineTo'](
        size / 2 + r * Math.cos(a),
        size / 2 + r * Math.sin(a)
      );
    }
    ctx.closePath();
    ctx.fill();

    return canvas;
  }

  // Simplified color parsing
  private parseColor(color: string): string[] {
    return ['rgba', '255', '255', '255', '1'];
  }
}