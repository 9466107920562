import { Component, OnInit, OnDestroy, signal, inject, ChangeDetectorRef, ChangeDetectionStrategy, PLATFORM_ID, NgZone, computed, effect, runInInjectionContext, ViewChild, HostListener, Input } from '@angular/core';
import { CommonModule, isPlatformBrowser, KeyValue} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Firestore, collection, collectionData, Timestamp } from '@angular/fire/firestore';
import { Storage, ref, getDownloadURL } from '@angular/fire/storage';
import { Observable, map, take, Subject, firstValueFrom, debounceTime, finalize, distinctUntilChanged } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnifiedSoundProfile, DEVICE_TYPES, DeviceType } from '@models/sound-profile.model';
import { SoundProfileVisualiserComponent } from '@shared/components/sound-profile-visualiser/sound-profile-visualiser.component';
import { SoundProfileService } from '@shared/services/sound-profile.service';
import { TimestampPipe } from '@shared/pipes/timestamp.pipe';
import { UserService } from '@services/user.service';
import { ToastService } from '@services/toast.service';
import { AudioDetectionService } from '@services/audio-detection.service';
import { Auth } from '@angular/fire/auth';
import { User } from '@models/user.model';
import { DeleteRunwayService } from '@services/delete-runway.service';
import { SoundProfileRecordingService } from '@shared/services/sound-profile-recording.service';
import { saveAs } from 'file-saver'; // You may need to install this package: npm install file-saver @types/file-saver
import { WebcamComponent } from '@shared/components/webcam/webcam.component';
import { DeviceSettingsService } from '@shared/services/device-settings.service';
import { DeviceSettings, DEFAULT_DEVICE_SETTINGS } from '@shared/models/device-settings.model';
import { WebcamControlService } from '@shared/services/webcam-control.service';
import { ProfileMapComponent } from '@shared/components/profile-map/profile-map.component';
import { ServiceReadinessComponent } from '@shared/components/service-readiness/service-readiness.component';
import { RouterModule } from '@angular/router';
import { SimilarityScoreComponent } from '@shared/components/similarity-score/similarity-score.component';
import { DEBUG_MODE } from '@shared/constants';
import { Injector } from '@angular/core';
import { AudioLibraryVersionsComponent } from '@shared/components/audio-library-versions/audio-library-versions.component';
import { AudioLibraryVersionsService } from '@shared/services/audio-library-versions.service';

type PatternType = 'blow' | 'laugh' | 'hello' | 'clap';
type TabType = 'positive' | 'negative';

@Component({
  selector: 'app-audio-settings',
  templateUrl: './audio-settings.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TimestampPipe,
    WebcamComponent,   
    
    SimilarityScoreComponent,
    RouterModule,
    AudioLibraryVersionsComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioSettingsComponent implements OnInit, OnDestroy {
  @ViewChild(WebcamComponent) webcamComponent!: WebcamComponent;

  private soundProfileRecordingService = inject(SoundProfileRecordingService);
  private firestore = inject(Firestore);
  private storage = inject(Storage);
  private userService = inject(UserService);
  private toastService = inject(ToastService);
  private audioDetectionService = inject(AudioDetectionService);
  private auth = inject(Auth);
  private deleteRunwayService = inject(DeleteRunwayService);
  private cdr = inject(ChangeDetectorRef);
  private platformId = inject(PLATFORM_ID);
  private soundProfileService = inject(SoundProfileService);
  private ngZone = inject(NgZone);
  private deviceSettingsService = inject(DeviceSettingsService);
  private webcamControlService = inject(WebcamControlService);
  private injector = inject(Injector);
  private versionsService = inject(AudioLibraryVersionsService);

  isRecording = signal(false);
  isTesting = signal(false);
  currentRecordingType = signal<'blow' | 'laugh' | 'clap' | 'hello' | null>(null);
  recordingProgress = signal(100);
  uploadProgress = signal(0);

  breathProfiles = signal<UnifiedSoundProfile[]>([]);
  laughProfiles = signal<UnifiedSoundProfile[]>([]);
  clapProfiles = signal<UnifiedSoundProfile[]>([]);
  helloProfiles = signal<UnifiedSoundProfile[]>([]);

  readonly deviceTypes: readonly DeviceType[] = DEVICE_TYPES;
  selectedDeviceType = signal<DeviceType>('desktop + webcam');
  allProfiles = computed(() => [
    ...this.breathProfiles(),
    ...this.laughProfiles(),
    ...this.clapProfiles(),
    ...this.helloProfiles()
  ]);

  public MAX_PROFILES_PER_TYPE = 10;
  private audioElement: HTMLAudioElement | null = null;
  private destroy$ = new Subject<void>();
  private mediaStream: MediaStream | null = null;

  currentUser: User | null = null;
  lastRecordedProfile: UnifiedSoundProfile | null = null;
  selectedProfile: UnifiedSoundProfile | null = null;

  isTestingAgainstProfiles = signal(false);
  testProgress = signal(0);
  testResult = signal<UnifiedSoundProfile | null>(null);

  selectedProfileEntries: KeyValue<string, any>[] = [];

  profiles: UnifiedSoundProfile[] = [];


  isUploading = signal(false);
  isPlaying = signal<string | null>(null);

  private recordingProgressValue = signal(100); // Start at 100%
  private uploadProgressValue = signal(0);
  private isUploadingValue = signal(false);

  expandedArrays: { [key: string]: boolean } = {};

  private userDisplayNames = new Map<string, string>();

  isExporting = signal(false);

  currentDeviceSettings = signal<DeviceSettings | null>(null);

  protected readonly activeTab = signal<TabType>('positive');

  setActiveTab(tab: TabType) {
    this.activeTab.set(tab);
  }

  private settingsUpdateSubject = new Subject<DeviceSettings>();

  private currentDeviceType = signal<DeviceType>('desktop + webcam');
  private toastSignal = signal<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);

  activeSection = signal<string>('library-controls');

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      this.activeSection.set(elementId);
    }
  }

  // Optional: Update active section on scroll
  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const sections = [
      'library-controls',
      'device-settings',
      'device-profiles',
      'sound-patterns',
      'profile-analysis'
    ];

    for (const section of sections) {
      const element = document.getElementById(section);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
          this.activeSection.set(section);
          break;
        }
      }
    }
  }

  // Add these properties
  requiredMatches = signal<number>(1);
  minimumScore = signal<number>(80);

  // Add these properties to your component
  stepValues = Array.from({ length: 101 }, (_, i) => i); // 0 to 100

  // Helper method to determine which values should show labels
  shouldShowLabel(value: number): boolean {
    if (value <= 10) return true; // Show every number 0-10
    if (value <= 30 && value % 5 === 0) return true; // Show every 5th number from 10-30
    if (value % 10 === 0) return true; // Show every 10th number after 30
    return false;
  }

  // Add new property for debouncing
  private settingChangeSubject = new Subject<{ setting: string; value: number }>();

  public deviceSettings = signal<DeviceSettings | null>(null);

  // Add to your class properties
  public soundStabilityDuration = signal<number>(500); // Default 500ms

  currentVersionUid = signal<string | null>(
    localStorage.getItem('currentAudioLibraryVersion')
  );

  constructor(

  ) {
    this.ngZone.run(() => {
      this.soundProfileRecordingService.isRecording$.subscribe(isRecording => {
        this.isRecording.set(isRecording);
        this.cdr.markForCheck();
      });

      this.soundProfileRecordingService.isUploading$.subscribe(isUploading => {
        this.isUploadingValue.set(isUploading);
        this.cdr.markForCheck();
      });

      this.soundProfileRecordingService.recordingProgress$.subscribe(progress => {
        this.recordingProgress.set(progress);
        this.cdr.markForCheck();
      });

      this.soundProfileRecordingService.uploadProgress$.subscribe(progress => {
        this.uploadProgress.set(progress);
        this.cdr.markForCheck();
      });
    });

    // Load initial profiles
    this.loadProfiles();

    effect(() => {
      const currentDeviceSettings = this.deviceSettingsService.deviceSettingsSignal();

      // Optionally trigger a toast or update detection parameters
      if (currentDeviceSettings) {
        runInInjectionContext(this.injector, () => {
          this.showSettingsUpdatedToast();
          this.audioDetectionService.updateDeviceType(this.selectedDeviceType());
        });
      }
    }, { allowSignalWrites: true });

    this.settingChangeSubject.pipe(
      debounceTime(300),
      distinctUntilChanged((prev, curr) =>
        prev.setting === curr.setting && prev.value === curr.value
      )
    ).subscribe(async ({ setting, value }) => {
      await this.updateSettingValue(setting, value);
    });

    // Initialize the current version
    this.loadCurrentVersion();

    // Listen for version restore events
    window.addEventListener('audioLibraryVersionRestored', ((event: CustomEvent) => {
      this.currentVersionUid.set(event.detail.versionUid);
      // Store in localStorage
      localStorage.setItem('currentAudioLibraryVersion', event.detail.versionUid);
    }) as EventListener);
  }

  private async loadSettings() {
    try {
      // Load device settings
      const deviceSettings = await this.deviceSettingsService.getAllDeviceSettings();
      this.currentDeviceSettings.set(deviceSettings[this.selectedDeviceType()]);
      
      // Load profiles
      await this.loadProfiles();
      
      // Trigger change detection
      this.cdr.markForCheck();
    } catch (error) {
      console.error('Error loading settings:', error);
      this.toastService.show('Error loading settings', 'error');
    }
  }

  async ngOnInit() {
    this.ngZone.run(() => {
      this.loadCurrentUser();
      if (isPlatformBrowser(this.platformId)) {
        this.loadProfiles();
      }
      this.subscribeToSoundProfileService();
    });

    // Load device settings
    const defaultDevice: DeviceType = 'desktop + webcam';
    try {
      const settings = await this.deviceSettingsService.getDeviceSettings(defaultDevice);
      console.log('[AudioSettings] Successfully loaded device settings:', {
        deviceType: settings.deviceType
      });
      this.currentDeviceSettings.set(settings);
    } catch (error) {
      console.error('[AudioSettings] Error loading device settings:', error);
      // Fallback to defaults if there's an error
      const defaultSettings = DEFAULT_DEVICE_SETTINGS[defaultDevice];
      this.currentDeviceSettings.set(defaultSettings);
    }

    // Setup debounced settings updates
    this.settingsUpdateSubject.pipe(
      debounceTime(1000), // Wait 1 second of no changes before updating
      takeUntil(this.destroy$)
    ).subscribe(async (settings) => {
      try {
        await this.deviceSettingsService.saveDeviceSettings(settings);
        this.currentDeviceSettings.set(settings);
        this.toastService.show('Settings updated successfully', 'success');
      } catch (error) {
        console.error('[AudioSettings] Error updating settings:', error);
        this.toastService.show('Error updating settings', 'error');
      }
    });

    // Initialize detection settings with default values
    this.updateCurrentDetectionSettings();

    // Initialize device settings
    this.deviceSettingsService.getDeviceSettings(this.selectedDeviceType())
      .then(settings => {
        this.deviceSettings.set(settings);
      });
  }

  // Update the computed properties
  public currentAudioLevel = computed(() => this.audioDetectionService.getCurrentAudioLevel()());
  public thresholdLevel = computed(() => {
    const settings = this.currentDeviceSettings();
    if (!settings) return 0;
    // Use the same value as the sound sensitivity slider
    return this.calculateSliderValue(settings.lowFrequencyEnergyRatio ?? 0.4);
  });

  private subscribeToSoundProfileService() {
    this.ngZone.run(() => {
      this.soundProfileRecordingService.isRecording$.pipe(takeUntil(this.destroy$)).subscribe(isRecording => {
        this.isRecording.set(isRecording);
        this.cdr.markForCheck();
      });
      this.soundProfileRecordingService.isUploading$.pipe(takeUntil(this.destroy$)).subscribe(isUploading => {
        this.isUploadingValue.set(isUploading);
        this.cdr.markForCheck();
      });
      this.soundProfileRecordingService.recordingProgress$.pipe(takeUntil(this.destroy$)).subscribe(progress => {
        this.recordingProgress.set(progress);
        this.cdr.markForCheck();
      });
      this.soundProfileRecordingService.uploadProgress$.pipe(takeUntil(this.destroy$)).subscribe(progress => {
        this.uploadProgress.set(progress);
        this.cdr.markForCheck();
      });
    });
  }

  async loadCurrentUser() {
    this.currentUser = await this.userService.getCurrentUser();
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement = null;
    }
    this.destroy$.next();
    this.destroy$.complete();
    // Instead, just stop listening
    this.audioDetectionService.stopListening();
    // Clean up event listener
    window.removeEventListener('audioLibraryVersionRestored', () => {});
  }

  private async loadProfiles() {
    this.ngZone.run(async () => {
      try {
        const profiles = await this.soundProfileService.getAllProfiles();
        this.breathProfiles.set(profiles.filter(p => p.type === 'blow'));
        this.laughProfiles.set(profiles.filter(p => p.type === 'laugh'));
        this.clapProfiles.set(profiles.filter(p => p.type === 'clap'));
        this.helloProfiles.set(profiles.filter(p => p.type === 'hello'));
        this.loadUserDisplayNames();
        this.cdr.markForCheck();
      } catch (error) {
        console.error('Error loading profiles:', error);
        // Handle error (e.g., show a toast message)
      }
    });
  }

  async startRecording(type: 'blow' | 'laugh' | 'clap' | 'hello') {
    this.isRecording.set(true);
    this.currentRecordingType.set(type);
    this.recordingProgress.set(100); // Reset to 100% at the start of recording

    const startTime = Date.now();
    const recordingDuration = 5000; // 5 seconds in milliseconds

    const progressInterval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progress = 100 - (elapsedTime / recordingDuration) * 100;
      this.recordingProgress.set(Math.max(0, Math.min(100, progress)));
      this.cdr.markForCheck();
    }, 50); // Update every 50ms for smoother animation

    try {
      const profile = await this.soundProfileRecordingService.recordAndSaveProfile(type);
      this.updateProfileList(type, profile);
      this.toastService.show(`${type} profile recorded successfully`, 'success');
    } catch (error) {
      console.error('Error recording and saving profile:', error);
      this.toastService.show(`Error recording ${type} profile`, 'error');
    } finally {
      clearInterval(progressInterval);
      this.isRecording.set(false);
      this.recordingProgress.set(100); // Reset to 100% after recording
    }
  }

  private updateProfileList(type: 'blow' | 'laugh' | 'clap' | 'hello', profile: UnifiedSoundProfile) {
    switch (type) {
      case 'blow':
        this.breathProfiles.set([...this.breathProfiles(), profile]);
        break;
      case 'laugh':
        this.laughProfiles.set([...this.laughProfiles(), profile]);
        break;
      case 'clap':
        this.clapProfiles.set([...this.clapProfiles(), profile]);
        break;
      case 'hello':
        this.helloProfiles.set([...this.helloProfiles(), profile]);
        break;
    }
  }

  async playAudio(profile: UnifiedSoundProfile) {
    try {
      if (!profile.downloadURL) {
        throw new Error('Download URL not available for this profile');
      }

      console.log(`Playing audio from URL: ${profile.downloadURL}`);

      if (this.audioElement) {
        this.audioElement.pause();
        this.audioElement.src = '';
      } else {
        this.audioElement = new Audio();
      }

      this.audioElement.src = profile.downloadURL;
      await this.audioElement.play();
      this.isPlaying.set(profile.id);

      this.audioElement.onended = () => {
        this.ngZone.run(() => {
          this.isPlaying.set(null);
          this.cdr.markForCheck();
        });
      };
    } catch (error) {
      console.error('Error playing audio:', error);
      this.toastService.show('Error playing audio', 'error');
    }
  }

  async updateDeviceType(profile: UnifiedSoundProfile) {
    try {
      if (profile.id && profile.deviceType) {
        await this.soundProfileService.updateDeviceType(profile.id, profile.deviceType);
        this.toastService.show('Device type updated successfully', 'success');
      } else {
        throw new Error('Invalid profile data');
      }
    } catch (error) {
      console.error('Error updating device type:', error);
      this.toastService.show('Error updating device type', 'error');
    }
  }

  async confirmDelete(profile: UnifiedSoundProfile) {
    if (confirm('Are you sure you want to delete this profile?')) {
      try {
        const filePath = `soundProfiles/${profile.filename}`; // Construct the correct file path
        const success = await this.deleteRunwayService.moveToDeleteRunway(filePath, profile.id);
        if (success) {
          await this.deleteRunwayService.deleteFirestoreDocument('config/soundSettings/soundProfiles', profile.id);
          this.toastService.show('Profile moved to delete runway', 'success');
          await this.loadProfiles(); // Reload profiles after deletion
        } else {
          throw new Error('Failed to move file to delete runway');
        }
      } catch (error) {
        console.error('Error moving profile to delete runway:', error);
        this.toastService.show('Error moving profile to delete runway', 'error');
      }
    }
  }

  showProfileDetails(profile: UnifiedSoundProfile) {
    this.selectedProfile = profile;
    this.selectedProfileEntries = Object.entries(profile)
      .map(([key, value]) => ({ key, value }))
      .filter(entry => this.shouldDisplayProperty(entry.key, entry.value));
    this.expandedArrays = {}; // Reset expanded arrays
    const modal = document.getElementById('profile_details_modal') as HTMLDialogElement;
    if (modal) {
      modal.showModal();
    }
  }

  private shouldDisplayProperty(key: string, value: any): boolean {
    // Exclude certain properties or types if needed
    const excludedKeys = ['id', 'filename']; // Add any keys you want to exclude
    return !excludedKeys.includes(key) && value !== null && value !== undefined;
  }

  toggleArrayExpansion(key: string) {
    this.expandedArrays[key] = !this.expandedArrays[key];
  }

  formatValue(value: any, key: string): string {
    if (value instanceof Timestamp) {
      return value.toDate().toLocaleString();
    }
    if (Array.isArray(value)) {
      if (this.expandedArrays[key]) {
        return JSON.stringify(value, null, 2);
      }
      return `[Array] (${value.length} items)`;
    }
    if (typeof value === 'number') {
      return value.toFixed(2);
    }
    return String(value);
  }

  trackByFn(index: number, item: UnifiedSoundProfile) {
    return item.id;
  }

  getAllProfiles(): UnifiedSoundProfile[] {
    return this.allProfiles();
  }

  async clearAllProfiles() {
    try {
      await this.soundProfileService.clearSoundProfiles();
      this.loadProfiles();
    } catch (error) {
      console.error('Error clearing profiles:', error);
      this.toastService.show('Error clearing profiles', 'error');
    }
  }

  private getProfilesForType(type: 'blow' | 'laugh' | 'clap' | 'hello'): UnifiedSoundProfile[] {
    switch (type) {
      case 'blow':
        return this.breathProfiles();
      case 'laugh':
        return this.laughProfiles();
      case 'clap':
        return this.clapProfiles();
      case 'hello':
        return this.helloProfiles();
    }
  }

  async testAgainstProfiles() {
    this.isTesting.set(true);
    this.testProgress.set(0);
    try {
      // Start recording without setting isRecording to true
      await this.soundProfileRecordingService.startRecording(true); // Add a parameter to indicate testing

      // Simulate progress (replace with actual progress if available)
      const progressInterval = setInterval(() => {
        this.testProgress.update(v => Math.min(v + 10, 90));
      }, 200);

      // Record for 3 seconds
      await new Promise(resolve => setTimeout(resolve, 3000));

      const result = await this.soundProfileRecordingService.stopRecordingAndAnalyze();
      const detectedType = this.determineProfileType(result);

      const testResult: UnifiedSoundProfile = {
        ...result,
        id: 'test-result',
        filename: 'test-result.webm',
        createdAt: Timestamp.now(),
        createdBy: 'Test',
        type: detectedType
      };

      this.updateProfilesWithTestResult(testResult);

      clearInterval(progressInterval);
      this.testProgress.set(100);
    } catch (error) {
      console.error('Error testing against profiles:', error);
      this.toastService.show('Error testing against profiles', 'error');
    } finally {
      setTimeout(() => {
        this.isTesting.set(false);
        this.testProgress.set(0);
      }, 1000);
    }
  }

  private determineProfileType(profile: Omit<UnifiedSoundProfile, 'id' | 'filename' | 'createdAt' | 'createdBy' | 'type'>): 'blow' | 'laugh' | 'clap' | 'hello' {
    // Blow sound characteristics
    const isLongSustained = profile.sustainedHighAmplitudeDuration > 0.5;
    const hasHighLowFrequencyRatio = profile.lowFrequencyEnergyRatio > 0.4;
    const hasLowAmplitudeVariation = profile.amplitudeVariation < 0.2;
    const hasHighAverageLevel = profile.averageLevel > 0.08;

    if (isLongSustained && hasHighLowFrequencyRatio && hasLowAmplitudeVariation && hasHighAverageLevel) {
      return 'blow';
    } else if (profile.duration < 0.5 && profile.peakAmplitude > 0.8) {
      return 'clap';
    } else if (profile.spectralCentroid > 2000 && profile.amplitudeVariation > 0.3) {
      return 'laugh';
    } else {
      return 'hello';
    }
  }

  private updateProfilesWithTestResult(testResult: UnifiedSoundProfile) {
    // Remove any existing test result from all profile arrays
    this.breathProfiles.update(profiles => profiles.filter(p => p.id !== 'test-result'));
    this.laughProfiles.update(profiles => profiles.filter(p => p.id !== 'test-result'));
    this.clapProfiles.update(profiles => profiles.filter(p => p.id !== 'test-result'));
    this.helloProfiles.update(profiles => profiles.filter(p => p.id !== 'test-result'));

    // Add the new test result to the appropriate array
    switch (testResult.type) {
      case 'blow':
        this.breathProfiles.update(profiles => [...profiles, testResult]);
        break;
      case 'laugh':
        this.laughProfiles.update(profiles => [...profiles, testResult]);
        break;
      case 'clap':
        this.clapProfiles.update(profiles => [...profiles, testResult]);
        break;
      case 'hello':
        this.helloProfiles.update(profiles => [...profiles, testResult]);
        break;
    }

    // Trigger change detection
    this.cdr.markForCheck();
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  private loadUserDisplayNames() {
    const userIds = new Set([
      ...this.breathProfiles().map(p => p.createdBy),
      ...this.laughProfiles().map(p => p.createdBy),
      ...this.clapProfiles().map(p => p.createdBy),
      ...this.helloProfiles().map(p => p.createdBy)
    ]);

    userIds.forEach(userId => {
      this.userService.getUserById(userId).pipe(
        take(1),
        map(user => {
          if (user) {
            return user.displayName || `${user.firstName} ${user.lastName}`.trim() || user.email || 'Unknown User';
          }
          return 'Unknown User';
        })
      ).subscribe(displayName => {
        this.userDisplayNames.set(userId, displayName);
        this.cdr.markForCheck();
      });
    });
  }

  getUserDisplayName(userId: string): string {
    return this.userDisplayNames.get(userId) || 'Loading...';
  }

  async exportAllProfiles() {
    this.isExporting.set(true);
    try {
      const allProfiles = {
        blow: this.breathProfiles(),
        hello: this.helloProfiles(),
        clap: this.clapProfiles(),
        laugh: this.laughProfiles()
      };

      const jsonContent = JSON.stringify(allProfiles, null, 2);
      const blob = new Blob([jsonContent], { type: 'application/json' });
      saveAs(blob, 'all_sound_profiles.json');

      this.toastService.show('Profiles exported successfully', 'success');
    } catch (error) {
      console.error('Error exporting profiles:', error);
      this.toastService.show('Error exporting profiles', 'error');
    } finally {
      this.isExporting.set(false);
    }
  }

  // Modify the silent update method
  private async updateDeviceSettingsSilently(settings: DeviceSettings) {
    console.log('[AudioSettings] Saving settings:', settings);
    this.currentDeviceSettings.set(settings); // Update UI immediately
    
    try {
      // Save to Firestore immediately under the correct path
      await this.deviceSettingsService.saveDeviceSettings({
        ...settings,
        deviceType: this.selectedDeviceType()
      });
      console.log('[AudioSettings] Settings saved to Firestore');
    } catch (error) {
      console.error('[AudioSettings] Error saving settings:', error);
      // Optionally show an error toast
      this.toastService.show('Error saving settings', 'error');
    }
  }

  // Update the change handlers
  onLowFrequencyEnergyRatioChange(value: number) {
    if (this.currentDeviceSettings()) {
      const settings = {
        ...this.currentDeviceSettings()!,
        lowFrequencyEnergyRatio: value / 100
      };
      this.updateDeviceSettingsSilently(settings);
      console.log('[AudioSettings] Low frequency energy ratio updated:', value / 100);
    }
  }

  onConsistencyToleranceChange(value: number) {
    if (this.currentDeviceSettings()) {
      const settings = {
        ...this.currentDeviceSettings()!,
        consistencyTolerance: value / 100
      };
      this.updateDeviceSettingsSilently(settings);
      console.log('[AudioSettings] Consistency tolerance updated:', value / 100);
    }
  }

  onFrequencyToleranceChange(value: number) {
    if (this.currentDeviceSettings()) {
      const settings = {
        ...this.currentDeviceSettings()!,
        frequencyTolerance: value
      };
      this.updateDeviceSettingsSilently(settings);
      console.log('[AudioSettings] Frequency tolerance updated:', value);
    }
  }

  // Keep the original updateDeviceSettings for explicit saves
  async updateDeviceSettings(settings: DeviceSettings) {
    try {
      await this.deviceSettingsService.saveDeviceSettings(settings);
      this.currentDeviceSettings.set(settings);
      this.toastService.show('Settings updated successfully', 'success');
    } catch (error) {
      console.error('[AudioSettings] Error updating device settings:', error);
      this.toastService.show('Error updating settings', 'error');
    }
  }

  async resetDeviceSettings(deviceType: DeviceType) {
    try {
      await this.deviceSettingsService.resetToDefaults(deviceType);
      const settings = await this.deviceSettingsService.getDeviceSettings(deviceType);
      this.currentDeviceSettings.set(settings);
      this.toastService.show('Settings reset to defaults', 'success');
      this.cdr.markForCheck(); // Ensure UI updates
    } catch (error) {
      console.error('Error resetting device settings:', error);
      this.toastService.show('Error resetting settings', 'error');
    }
  }

  async onDeviceTypeChange(deviceType: DeviceType) {
    try {
      // Update selected device type
      this.selectedDeviceType.set(deviceType);
      
      // Load device-specific settings
      const settings = await this.deviceSettingsService.getDeviceSettings(deviceType);
      this.currentDeviceSettings.set(settings);
      
      // Update detection settings
      this.requiredMatches.set(settings.requiredMatches ?? 1);
      this.minimumScore.set(settings.minimumScore ?? 80);
      this.soundStabilityDuration.set(settings.soundStabilityDuration ?? 500);
      
      // Update WebcamControlService
      this.webcamControlService.setSelectedDeviceType(deviceType);

      if (DEBUG_MODE) {
        console.log(`[AudioSettings] Loaded settings for ${deviceType}:`, settings);
      }
    } catch (error) {
      console.error(`[AudioSettings] Error loading settings for ${deviceType}:`, error);
      this.toastService.show('Error loading device settings', 'error');
    }
  }

  // Add this method alongside the other change handlers
  onLowFrequencyThresholdChange(value: number) {
    if (this.currentDeviceSettings()) {
      const settings = {
        ...this.currentDeviceSettings()!,
        lowFrequencyThreshold: value
      };
      this.updateDeviceSettingsSilently(settings);
      console.log('[AudioSettings] Low frequency threshold updated:', value);
    }
  }

  // Add this method to the existing methods
  async exportDeviceSettings() {
    this.isExporting.set(true);
    try {
      const deviceSettings = await this.deviceSettingsService.getAllDeviceSettings();

      const jsonContent = JSON.stringify(deviceSettings, null, 2);
      const blob = new Blob([jsonContent], { type: 'application/json' });
      saveAs(blob, 'device_settings_export.json');

      this.toastService.show('Device settings exported successfully', 'success');
    } catch (error) {
      console.error('Error exporting device settings:', error);
      this.toastService.show('Error exporting device settings', 'error');
    } finally {
      this.isExporting.set(false);
    }
  }

  private showSettingsUpdatedToast() {
    this.toastSignal.set({
      message: 'Device settings updated successfully',
      type: 'success'
    });
  }

  showDeviceTypeWarning(): boolean {
    // Show warning if device type is different between audio settings and webcam
    return this.selectedDeviceType() !== this.webcamControlService.getSelectedDeviceType();
  }

  getDeviceTypeProfiles(): UnifiedSoundProfile[] {
    const currentDeviceType = this.selectedDeviceType();

    // Combine all profiles and filter by selected device type
    const allProfiles = [
      ...this.breathProfiles(),
      ...this.laughProfiles(),
      ...this.clapProfiles(),
      ...this.helloProfiles()
    ];

    return allProfiles.filter(profile => profile.deviceType === currentDeviceType);
  }

  testSoundProfile(profile: UnifiedSoundProfile) {
    try {
      // Ensure a device type is selected
      const deviceType = this.selectedDeviceType();
      if (!deviceType) {
        this.toastService.show('Please select a device type first', 'error');
        return;
      }

      // Show a toast with profile ID and device type
      this.toastService.show(
        `Testing sound profile ${profile.id} against audio detection for type ${deviceType}`,
        'info'
      );

      // Trigger webcam start
      if (this.webcamComponent) {
        // Ensure device type matches
        this.webcamComponent.onDeviceTypeChange(deviceType);

        // Start webcam if not already started
        if (!this.webcamComponent.webcamStarted) {
          this.webcamComponent.startWebcam();
        }
      }

      // Optional: Log the test
      console.log('Testing sound profile:', {
        profileId: profile.id,
        deviceType: deviceType,
        profileType: profile.type
      });
    } catch (error) {
      console.error('Error testing sound profile:', error);
      this.toastService.show('Error testing sound profile', 'error');
    }
  }

  simulateNoiseDetection() {
    // Set testing state
    this.isTesting.set(true);

    // Call the noise simulation method
    this.audioDetectionService.simulateNoiseAndDetectBlow()
      .pipe(
        finalize(() => {
          // Reset testing state when complete
          this.isTesting.set(false);
        })
      )
      .subscribe({
        next: (isBlowDetected) => {
          // Show toast with detection result
          this.toastService.show(
            isBlowDetected
              ? 'Blow Detected in Simulated Noise! 🚨'
              : 'No Blow Detected in Simulated Noise',
            isBlowDetected ? 'success' : 'info'
          );
        },
        error: (error) => {
          console.error('Noise simulation error:', error);
          this.toastService.show('Error in noise simulation', 'error');
        }
      });
  }

  testProfileNoise(profile: UnifiedSoundProfile) {
    try {
      // Ensure a device type is selected
      const deviceType = this.selectedDeviceType();
      if (!deviceType) {
        this.toastService.show('Please select a device type first', 'error');
        return;
      }

      // Set testing state
      this.isTesting.set(true);

      // Show a toast with profile details
      this.toastService.show(
        `Testing noise for profile ${profile.id} (${profile.type})`,
        'info'
      );

      // Trigger webcam start if not already started
      if (this.webcamComponent && !this.webcamComponent.webcamStarted) {
        this.webcamComponent.onDeviceTypeChange(deviceType);
        this.webcamComponent.startWebcam();
      }

      // Simulate noise detection with the specific profile
      this.audioDetectionService.simulateNoiseAndDetectBlow(profile)
        .pipe(
          finalize(() => {
            // Reset testing state when complete
            this.isTesting.set(false);
          })
        )
        .subscribe({
          next: (isBlowDetected) => {
            // Show toast with detection result
            this.toastService.show(
              isBlowDetected
                ? `Blow Detected for Profile ${profile.id}! 🚨`
                : `No Blow Detected for Profile ${profile.id}`,
              isBlowDetected ? 'success' : 'info'
            );
          },
          error: (error) => {
            console.error('Profile noise test error:', error);
            this.toastService.show('Error testing profile noise', 'error');
            this.isTesting.set(false);
          }
        });

    } catch (error) {
      console.error('Error testing profile noise:', error);
      this.toastService.show('Error testing profile noise', 'error');
      this.isTesting.set(false);
    }
  }

  testDeviceTypeProfile(profile: UnifiedSoundProfile) {
    try {
      // Ensure a device type is selected
      const deviceType = this.selectedDeviceType();
      if (!deviceType) {
        this.toastService.show('Please select a device type first', 'error');
        return;
      }

      // Set testing state
      this.isTesting.set(true);

      // Show a toast with profile details
      this.toastService.show(
        `Testing device type profile ${profile.id} for ${deviceType}`,
        'info'
      );

      // Trigger webcam start if not already started
      if (this.webcamComponent && !this.webcamComponent.webcamStarted) {
        this.webcamComponent.onDeviceTypeChange(deviceType);
        this.webcamComponent.startWebcam();
      }

      // Prepare for device type profile testing
      this.deviceSettingsService.testDeviceTypeProfile(profile, deviceType)
        .pipe(
          finalize(() => {
            // Reset testing state when complete
            this.isTesting.set(false);
          })
        )
        .subscribe({
          next: (testResult) => {
            // Show toast with test result
            this.toastService.show(
              testResult.success
                ? `Device Type Profile ${profile.id} Tested Successfully! ✅`
                : `Device Type Profile ${profile.id} Test Failed ❌`,
              testResult.success ? 'success' : 'error'
            );

            // Optional: Log detailed test results
            console.log('Device Type Profile Test Result:', testResult);
          },
          error: (error) => {
            console.error('Device type profile test error:', error);
            this.toastService.show('Error testing device type profile', 'error');
          }
        });

    } catch (error) {
      console.error('Error testing device type profile:', error);
      this.toastService.show('Error testing device type profile', 'error');
      this.isTesting.set(false);
    }
  }

  public calculateSensitivityValue(sliderValue: number): number {
    return sliderValue / 100; // Simple linear conversion
  }

  public calculateSliderValue(sensitivity: number): number {
    return sensitivity * 100; // Simple linear conversion
  }

  // Modify the existing onSettingChange method
  async onSettingChange(setting: string, value: number) {
    if (!this.currentDeviceSettings()) return;

    // Update UI immediately
    const settingValue = setting === 'lowFrequencyEnergyRatio'
      ? this.calculateSensitivityValue(value)
      : value;

    const updatedSettings = {
      ...this.currentDeviceSettings()!,
      [setting]: settingValue
    };

    // Update local state
    this.currentDeviceSettings.set(updatedSettings);

    // Debounce the actual save and toast
    this.settingChangeSubject.next({ setting, value });
  }

  // New method to handle the actual update
  private async updateSettingValue(setting: string, value: number) {
    try {
      const settingValue = setting === 'lowFrequencyEnergyRatio'
        ? this.calculateSensitivityValue(value)
        : value;

      const updatedSettings = {
        ...this.currentDeviceSettings()!,
        [setting]: settingValue
      };

      await this.deviceSettingsService.saveDeviceSettings(updatedSettings);
      this.toastService.show('Settings updated successfully', 'success');
    } catch (error) {
      console.error(`[AudioSettings] Error updating ${setting}:`, error);
      // Revert on error
      const settings = await this.deviceSettingsService.getDeviceSettings(this.selectedDeviceType()!);
      this.currentDeviceSettings.set(settings);
      this.toastService.show('Error saving settings', 'error');
    }
  }

  hasChangedFromDefault(): boolean {
    const currentSettings = this.currentDeviceSettings();
    const deviceType = this.selectedDeviceType();

    if (!currentSettings || !deviceType) {
      return false;
    }

    const defaultSettings = DEFAULT_DEVICE_SETTINGS[deviceType];

    // Compare relevant settings
    return (
      currentSettings.lowFrequencyEnergyRatio !== defaultSettings.lowFrequencyEnergyRatio ||
      currentSettings.consistencyTolerance !== defaultSettings.consistencyTolerance ||
      currentSettings.lowFrequencyThreshold !== defaultSettings.lowFrequencyThreshold ||
      currentSettings.frequencyTolerance !== defaultSettings.frequencyTolerance
    );
  }

  getBlowProfiles(): UnifiedSoundProfile[] {
    return this.getDeviceTypeProfiles().filter(profile => profile.type === 'blow');
  }

  getNonBlowProfiles(): UnifiedSoundProfile[] {
    return this.getDeviceTypeProfiles().filter(profile => profile.type !== 'blow');
  }

  

  // Single method to update detection settings
  private updateDetectionSettings(settings: { requiredMatches: number; minimumScore: number }) {
    if (DEBUG_MODE) {
      console.log('Updating detection settings:', settings);
    }
    this.audioDetectionService.updateDetectionSettings(settings);
  }

  // Remove all other variations of detection settings methods
  // and replace any calls to them with this pattern:
  private updateCurrentDetectionSettings() {
    const settings = {
      requiredMatches: this.requiredMatches(),
      minimumScore: this.minimumScore()
    };
    this.updateDetectionSettings(settings);
  }

  onRequiredMatchesChange(value: number) {
    this.requiredMatches.set(value);
    this.updateCurrentDetectionSettings();
  }

  onMinimumScoreChange(value: number) {
    this.minimumScore.set(value);
    this.updateCurrentDetectionSettings();
  }

  // Update the duration calculation method
  public onSoundStabilityChange(value: number) {
    if (!this.currentDeviceSettings()) return;
    
    // Convert slider value (0-100) to duration (200ms to 5000ms)
    const durationMs = Math.floor(200 + (value * 48)); // Maps 0-100 to 200-5000ms
    this.soundStabilityDuration.set(durationMs);
    
    // Update the service
    this.audioDetectionService.setSoundStabilityDuration(durationMs);
    
    // Update settings
    const updatedSettings = {
        ...this.currentDeviceSettings()!,
        soundStabilityDuration: durationMs
    };
    
    // Save the updated settings
    this.updateDeviceSettingsSilently(updatedSettings);
  }

  // Update the helper method for slider value calculation
  public calculateStabilitySliderValue(durationMs: number): number {
    return Math.floor((durationMs - 200) / 48); // Maps 200-5000ms to 0-100
  }

  // Add helper method for formatting duration
  public formatStabilityDuration(ms: number): string {
    return ms >= 1000 ? `${(ms/1000).toFixed(1)}s` : `${ms}ms`;
  }

  toggleVersionsDrawer() {
    const drawer = document.getElementById('audio-library-versions-drawer') as HTMLInputElement;
    drawer.checked = !drawer.checked;
  }

  

  async loadCurrentVersion() {
    try {
      // Load device settings
      const deviceSettings = await this.deviceSettingsService.getAllDeviceSettings();
      this.currentDeviceSettings.set(deviceSettings[this.selectedDeviceType()]);
      
      // Load profiles
      await this.loadProfiles();
      
      // Update detection settings
      const webcamSettings = deviceSettings['desktop + webcam'];
      if (webcamSettings) {
        this.requiredMatches.set(webcamSettings.requiredMatches);
        this.minimumScore.set(webcamSettings.minimumScore);
        this.soundStabilityDuration.set(webcamSettings.soundStabilityDuration);
        
        // Update detection service
        this.updateDetectionSettings({
          requiredMatches: webcamSettings.requiredMatches,
          minimumScore: webcamSettings.minimumScore
        });
      }
      
      this.cdr.markForCheck();
      console.log('✅ Current version loaded successfully');
    } catch (error) {
      console.error('❌ Error loading current version:', error);
      this.toastService.show('Error loading settings', 'error');
    }
  }
}