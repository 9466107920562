import { Injectable, signal } from '@angular/core';

export interface Toast {
  message: string;
  type: 'success' | 'error' | 'info';
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toasts = signal<Toast[]>([]);
  private counter = 0;

  getToasts() {
    return this.toasts;
  }

  showToast(message: string, type: 'success' | 'error' | 'info' = 'info') {
    const id = this.counter++;
    this.toasts.update(toasts => [...toasts, { message, type, id }]);
    setTimeout(() => this.removeToast(id), 5000); // Remove after 5 seconds
  }

  removeToast(id: number) {
    this.toasts.update(toasts => toasts.filter(t => t.id !== id));
  }

  show(message: string, type: 'success' | 'error' | 'info', showRefresh: boolean = false) {
    // Create the toast container
    const toast = document.createElement('div');
    toast.className = 'toast toast-end toast-bottom';

    // Create the alert div
    const alert = document.createElement('div');
    alert.className = `alert ${this.getAlertClass(type)}`;

    // Create the message span
    const messageSpan = document.createElement('span');
    messageSpan.textContent = message;
    alert.appendChild(messageSpan);

    // Add refresh button if requested
    if (showRefresh) {
      const refreshButton = document.createElement('button');
      refreshButton.className = 'btn btn-sm btn-ghost';
      refreshButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
        </svg>
        Refresh
      `;
      refreshButton.onclick = () => window.location.reload();
      alert.appendChild(refreshButton);
    }

    toast.appendChild(alert);
    document.body.appendChild(toast);

    // Remove the toast after 5 seconds
    setTimeout(() => {
      document.body.removeChild(toast);
    }, 5000);
  }

  hide() {
    // Implementation to hide the current toast
    // This may involve removing the toast element from the DOM
  }

  private getAlertClass(type: 'success' | 'error' | 'info'): string {
    switch (type) {
      case 'success':
        return 'alert-success';
      case 'error':
        return 'alert-error';
      case 'info':
        return 'alert-info';
      default:
        return 'alert-info';
    }
  }
}