import { Component, OnInit, signal, computed, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { 
  Firestore,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  DocumentData,
  CollectionReference,
  DocumentReference,
  Timestamp
} from '@angular/fire/firestore';
import { UserService } from '@services/user.service';
import { User } from '@shared/models/user.model';
import { Subscription } from 'rxjs';
import { ToastService } from '@shared/services/toast.service';
import { Video, VideoStatus } from '@shared/models/video.model';
import { VideoDetails, PatientDataFields } from '@shared/models/video-details.model';
import { FormatDatePipe } from '@shared/pipes/format-date.pipe';

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
  occupation: string;
}

interface VideoWithUploaderDetails extends Video {
  uploaderDetails?: {
    displayName: string;
    email: string;
    organizationName: string;
    occupation: string;
  };
}

@Component({
  selector: 'app-patient-data',
  standalone: true,
  imports: [CommonModule, FormatDatePipe, RouterModule],
  templateUrl: './patient-data.component.html',
  styles: []
})
export class PatientDataComponent implements OnInit, OnDestroy {
  private videosSignal = signal<VideoWithUploaderDetails[]>([]);
  videos = computed(() => {
    let filteredVideos = this.videosSignal().filter(video => video.status === 'submitted');
    
    // Apply sorting if a sort field is selected
    if (this.sortField()) {
      filteredVideos = [...filteredVideos].sort((a, b) => {
        let aValue: any;
        let bValue: any;
        
        switch (this.sortField()) {
          case 'uploaderName':
            aValue = a.uploaderDetails?.displayName || '';
            bValue = b.uploaderDetails?.displayName || '';
            return this.sortDirection() === 'asc' 
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          case 'organization':
            aValue = a.uploaderDetails?.organizationName || '';
            bValue = b.uploaderDetails?.organizationName || '';
            return this.sortDirection() === 'asc' 
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          case 'occupation':
            aValue = a.uploaderDetails?.occupation || '';
            bValue = b.uploaderDetails?.occupation || '';
            return this.sortDirection() === 'asc' 
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          case 'createdAt':
            const aDate = a.createdAt instanceof Timestamp ? a.createdAt.toDate() : a.createdAt;
            const bDate = b.createdAt instanceof Timestamp ? b.createdAt.toDate() : b.createdAt;
            return this.sortDirection() === 'asc' 
              ? aDate.getTime() - bDate.getTime()
              : bDate.getTime() - aDate.getTime();
          default:
            return 0;
        }
      });
    }
    
    return filteredVideos;
  });

  private userService = inject(UserService);
  private toastService = inject(ToastService);
  private formatDatePipe = inject(FormatDatePipe);
  user = signal<User | null>(null);
  private userSubscription: Subscription | null = null;
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private firestore = inject(Firestore);

  isAdmin = computed(() => {
    const user = this.user();
    return (user?.roles?.includes('admin') || user?.roles?.includes('superadmin')) ?? false;
  });

  patientDataFields: PatientDataFields = {
    eventDuration: true,
    frequency: true,
    duration: true,
    epilepsy: true,
    medication: true,
    seizureOccurrence: true,
    visitDuration: false,
    easeOfUse: false,
    patientEaseOfUse: false,
    additionalComments: false,
    patientConsent: true
  };

  sortField = signal<'uploaderName' | 'organization' | 'occupation' | 'createdAt' | null>('createdAt');
  sortDirection = signal<'asc' | 'desc'>('desc');

  ngOnInit() {
    this.subscribeToUser();
    
    // Check for video ID in the route parameters
    this.route.queryParams.subscribe(params => {
      const videoId = params['videoId'];
      if (videoId) {
        this.openVideoById(videoId);
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  private subscribeToUser() {
    this.userSubscription = this.userService.getUserState().subscribe(user => {
      this.user.set(user);
      if (user) {
        this.loadVideos(user.uid);
      } else {
        this.videosSignal.set([]);
      }
    });
  }

  private async loadVideos(userId: string) {
    try {
      const videosCollection = collection(this.firestore, 'videos') as CollectionReference<DocumentData>;
      const userVideosQuery = this.isAdmin() 
        ? query(videosCollection)
        : query(videosCollection, where('userId', '==', userId));
      
      onSnapshot(userVideosQuery, async (snapshot) => {
        const videosPromises = snapshot.docs.map(async (docSnapshot) => {
          const videoData = this.convertToVideo(docSnapshot);
          
          // Skip fetching user details if userId is empty
          if (!videoData.userId) {
            return videoData;
          }
          
          try {
            console.log('Fetching user with ID:', videoData.userId);
            const userDocRef = doc(this.firestore, 'users', videoData.userId.trim());
            const userDocSnapshot = await getDoc(userDocRef);
            
            if (userDocSnapshot.exists()) {
              const userData = userDocSnapshot.data() as UserData;
              return {
                ...videoData,
                uploaderDetails: {
                  displayName: `${userData.firstName} ${userData.lastName}`,
                  email: userData.email,
                  organizationName: userData.organizationName,
                  occupation: userData.occupation
                }
              };
            }
            return videoData;
          } catch (error) {
            console.error('Error fetching user details for ID:', videoData.userId, error);
            return videoData;
          }
        });

        const videosArray = await Promise.all(videosPromises);
        this.videosSignal.set(videosArray);
      }, (error) => {
        console.error('Error fetching videos:', error);
        this.toastService.show('Error loading patient data', 'error');
      });
    } catch (error) {
      console.error('Error in loadVideos:', error);
      this.toastService.show('Error loading videos', 'error');
    }
  }

  private convertToVideo(doc: any): Video {
    const data = doc.data();
    const createdAt = this.ensureValidDate(data['createdAt']);
    const patientDateOfBirth = this.parseDate(data['patientDateOfBirth']);
    
    // Add validation for userId
    const userId = data['userId'];
    if (!userId) {
      console.warn('Video document is missing userId:', doc.id);
    }
    
    return {
      id: doc.id,
      patientFirstName: data['patientFirstName'] as string,
      patientLastName: data['patientLastName'] as string,
      patientDateOfBirth: patientDateOfBirth ? new Date(this.formatDatePipe.transform(patientDateOfBirth) || '') : null,
      description: data['description'] as string,
      status: data['status'] as VideoStatus,
      createdAt: createdAt,
      userId: userId || '', // Provide default empty string if userId is missing
      videoUrl: data['videoUrl'] as string | undefined,
      priorityScore: data['priorityScore'] as number,
      additionalInfo: data['additionalInfo'] as VideoDetails || { symptoms: {}, conditions: {} }
    };
  }

  private ensureValidDate(value: any): Date | Timestamp {
    if (value instanceof Timestamp) {
      return value;
    }
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'string') {
      const parsedDate = new Date(value);
      return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
    }
    if (typeof value === 'object' && value && 'seconds' in value && 'nanoseconds' in value) {
      return new Timestamp(value.seconds, value.nanoseconds);
    }
    return new Date(); // Default to current date if all else fails
  }

  private parseDate(value: any): Date | null {
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'string') {
      const parsedDate = new Date(value);
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }
    if (typeof value === 'object' && value && 'seconds' in value && 'nanoseconds' in value) {
      return new Date(value.seconds * 1000 + value.nanoseconds / 1000000);
    }
    return null;
  }

  toggleSort(field: 'uploaderName' | 'organization' | 'occupation' | 'createdAt') {
    if (this.sortField() === field) {
      // If already sorting by this field, toggle direction
      this.sortDirection.update(current => current === 'asc' ? 'desc' : 'asc');
    } else {
      // If sorting by a new field, set it and default to ascending
      this.sortField.set(field);
      this.sortDirection.set('asc');
    }
  }

  openVideoById(videoId: string) {
    const video = this.videos().find(v => v.id === videoId);
    if (video) {
      this.router.navigate(['/admin/all-videos'], { 
        queryParams: { videoId: video.id },
        replaceUrl: false  // This ensures the URL is updated in the browser
      });
    }
  }

  viewVideo(video: Video) {
    // Navigate to all-videos with videoId and update URL
    this.router.navigate(['/admin/all-videos'], { 
      queryParams: { videoId: video.id }
    });
  }
}