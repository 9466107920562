import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { SoundProfileService } from './sound-profile.service';
import { DEVICE_TYPES, DeviceType } from '@models/sound-profile.model';

@Injectable({
  providedIn: 'root'
})
export class WebcamControlService {
  private stream: MediaStream | null = null;
  private webcamActiveSubject = new BehaviorSubject<boolean>(false);
  webcamActive$: Observable<boolean> = this.webcamActiveSubject.asObservable();

  private selectedDeviceTypeSubject = new BehaviorSubject<DeviceType>('open mic');
  selectedDeviceType$: Observable<DeviceType> = this.selectedDeviceTypeSubject.asObservable();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private soundProfileService: SoundProfileService
  ) {}

  async startWebcam(constraints?: MediaStreamConstraints): Promise<MediaStream> {
    const defaultConstraints: MediaStreamConstraints = {
      audio: true,
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        facingMode: 'user'
      }
    };

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints || defaultConstraints);
      this.stream = stream;
      this.webcamActiveSubject.next(true);
      return stream;
    } catch (error) {
      console.error('Error accessing media devices:', error);
      throw error;
    }
  }

  stopWebcam(): void {
    console.group('WebcamControlService: stopWebcam');
    if (this.stream) {
      console.log('Stopping webcam tracks');
      this.stream.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
      this.stream = null;
    } else {
      console.log('No active stream to stop');
    }
    this.webcamActiveSubject.next(false);
    console.groupEnd();
  }

  toggleCamera(enable: boolean): void {
    if (this.stream) {
      const videoTrack = this.stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = enable;
      }
    }
  }

  getStream(): MediaStream | null {
    return this.stream;
  }

  isWebcamActive(): boolean {
    return this.webcamActiveSubject.value;
  }

  muteAudioOutput(videoElement: HTMLVideoElement): void {
    if (videoElement) {
      videoElement.muted = true;
      console.log('Audio output muted');
    }
  }

  unmuteAudioOutput(videoElement: HTMLVideoElement): void {
    if (videoElement) {
      videoElement.muted = false;
      console.log('Audio output unmuted');
    }
  }

  getDeviceTypes(): readonly DeviceType[] {
    return DEVICE_TYPES;
  }

  setSelectedDeviceType(deviceType: DeviceType): void {
    this.selectedDeviceTypeSubject.next(deviceType);
  }

  getSelectedDeviceType(): DeviceType {
    return this.selectedDeviceTypeSubject.value;
  }
}