import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

interface TimestampLike {
  seconds: number;
  nanoseconds: number;
}

@Pipe({
  name: 'timestamp',
  standalone: true
})
export class TimestampPipe implements PipeTransform {
  transform(
    value: Date | Timestamp | TimestampLike | null | undefined, 
    format: 'short' | 'full' = 'full'
  ): string | null {
    const date = this.getDate(value);
    if (!date) return null;

    if (format === 'short') {
      return this.formatShortDate(date);
    }
    
    return date.toString(); // Original full format
  }

  private getDate(value: Date | Timestamp | TimestampLike | null | undefined): Date | null {
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    if (value instanceof Date) {
      return value;
    }
    if (this.isTimestampLike(value)) {
      return new Date(value.seconds * 1000 + value.nanoseconds / 1000000);
    }
    return null;
  }

  private formatShortDate(date: Date): string {
    return date.toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(',', ' at');
  }

  private isTimestampLike(value: any): value is TimestampLike {
    return (
      typeof value === 'object' &&
      value !== null &&
      'seconds' in value &&
      'nanoseconds' in value &&
      typeof value.seconds === 'number' &&
      typeof value.nanoseconds === 'number'
    );
  }
}