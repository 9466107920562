<div class="space-y-2">
    <div class="card bg-base-200 mb-4">
        <div class="card-body p-4">
            <div class="flex items-center gap-4">
                <!-- Required Matches Control -->
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text">Required Matches</span>
                        <span class="label-text-alt">{{ requiredMatches() }} of {{ maxRequiredMatches() }}</span>
                    </label>
                    <input 
                        type="range" 
                        min="1" 
                        [max]="maxRequiredMatches()" 
                        [value]="requiredMatches()" 
                        class="range range-primary range-sm" 
                        (input)="onRequiredMatchesChange($event)"
                    />
                </div>

                <!-- Minimum Score Control -->
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text">Minimum Score</span>
                        <span class="label-text-alt">{{ minimumScore() }}%</span>
                    </label>
                    <input 
                        type="range" 
                        min="0" 
                        max="100" 
                        [value]="minimumScore()" 
                        class="range range-primary range-sm" 
                        (input)="onMinimumScoreChange($event)"
                    />
                </div>
            </div>
        </div>
    </div>
    @for (profile of filteredProfiles(); track profile.id) {
    <div class="card bg-base-200">
        <div class="card-body p-4">
            <!-- Single Row Layout -->
            <div class="flex items-center gap-2">
                <!-- Profile Identification -->
                <div class="badge badge-sm">{{ profile.id }}</div>
                <div class="badge badge-sm">{{ profile.deviceType }}</div>
                
                <!-- Progress Indicator -->
                <progress 
                    class="progress w-32" 
                    [class]="getProgressClass(getProgressValueById(profile.id))"
                    [value]="getProgressValueById(profile.id)" 
                    max="100">
                </progress>
                <span class="text-xs tabular-nums w-12 text-right">
                    {{ (similarityScores()[profile.id] || 0).toFixed(0) }}%
                </span>

                <!-- Compact Details Button -->
                <button 
                    class="btn btn-xs btn-neutral"
                    (click)="toggleProfileDetails(profile.id)">
                    Details
                    <svg xmlns="http://www.w3.org/2000/svg" 
                         class="h-4 w-4 transition-transform" 
                         [class.rotate-180]="expandedProfiles()[profile.id]"
                         fill="none" 
                         viewBox="0 0 24 24" 
                         stroke="currentColor">
                        <path stroke-linecap="round" 
                              stroke-linejoin="round" 
                              stroke-width="2" 
                              d="M19 9l-7 7-7-7" />
                    </svg>
                </button>
            </div>

            <!-- Detailed Breakdown (Collapsible) -->
            @if (expandedProfiles()[profile.id]) {
            <div class="mt-4">
                <!-- Frequency Match (30%) -->
                <div class="mb-3">
                    <div class="text-xs font-medium mb-1 flex justify-between">
                        <span>Frequency Match (30%)</span>
                        <span>{{ formatNumber(getScoreDetail(profile.id).frequencyScore) }}%</span>
                    </div>
                    <progress class="progress progress-primary w-full" 
                        [value]="getScoreDetail(profile.id).frequencyScore" 
                        max="100">
                    </progress>
                    <div class="text-xs opacity-70 mt-1">
                        Current: {{ formatNumber(getScoreDetail(profile.id).currentValues.peakFrequency) }}Hz vs 
                        Profile: {{ formatNumber(getScoreDetail(profile.id).profileValues.peakFrequency) }}Hz
                    </div>
                </div>

                <!-- Consistency Match (20%) -->
                <div class="mb-3">
                    <div class="text-xs font-medium mb-1 flex justify-between">
                        <span>Consistency (20%)</span>
                        <span>{{ formatNumber(getScoreDetail(profile.id).consistencyScore) }}%</span>
                    </div>
                    <progress class="progress progress-secondary w-full" 
                        [value]="getScoreDetail(profile.id).consistencyScore" 
                        max="100">
                    </progress>
                </div>

                <!-- Energy Ratio Match (20%) -->
                <div class="mb-3">
                    <div class="text-xs font-medium mb-1 flex justify-between">
                        <span>Energy Ratio (20%)</span>
                        <span>{{ formatNumber(getScoreDetail(profile.id).energyRatioScore) }}%</span>
                    </div>
                    <progress class="progress progress-accent w-full" 
                        [value]="getScoreDetail(profile.id).energyRatioScore" 
                        max="100">
                    </progress>
                </div>

                <!-- Amplitude Match (15%) -->
                <div class="mb-3">
                    <div class="text-xs font-medium mb-1 flex justify-between">
                        <span>Amplitude (15%)</span>
                        <span>{{ formatNumber(getScoreDetail(profile.id).amplitudeScore) }}%</span>
                    </div>
                    <progress class="progress progress-info w-full" 
                        [value]="getScoreDetail(profile.id).amplitudeScore" 
                        max="100">
                    </progress>
                </div>

                <!-- Threshold Check (15%) -->
                <div class="mb-3">
                    <div class="text-xs font-medium mb-1 flex justify-between">
                        <span>Threshold Check (15%)</span>
                        <span>{{ formatNumber(getScoreDetail(profile.id).thresholdScore) }}%</span>
                    </div>
                    <progress class="progress progress-success w-full" 
                        [value]="getScoreDetail(profile.id).thresholdScore" 
                        max="100">
                    </progress>
                </div>

                <!-- Current Settings -->
                <div class="text-xs mt-4 space-y-1 opacity-70">
                    <div>Min Frequency: {{ currentSettings()?.lowFrequencyThreshold }}Hz</div>
                    <div>Frequency Tolerance: ±{{ currentSettings()?.frequencyTolerance }}Hz</div>
                    <div>Required Matches: {{ requiredMatches() }}</div>
                    <div>Minimum Score: {{ minimumScore() }}%</div>
                </div>
            </div>
            }
        </div>
    </div>
    }
</div> 