import { Timestamp } from '@angular/fire/firestore';

export const DEVICE_TYPES = [  
  'desktop + microphone',
  'open mic',
  'desktop + webcam',
  'mobile',
  'mobile + headphone'
] as const;

export type DeviceType = typeof DEVICE_TYPES[number];

export interface UnifiedSoundProfile {
  id: string;
  type: 'blow' | 'laugh' | 'clap' | 'hello';
  filename: string;
  createdAt: Timestamp;
  createdBy: string;
  deviceType: DeviceType | null;
  peakAmplitude: number;
  averageAmplitude: number;
  duration: number;
  riseTime: number;
  fallTime: number;
  dominantFrequency: number;
  breathingPattern: number[] | null;
  averageLevel: number;
  maxLevel: number;
  minLevel: number;
  threshold: number;
  frequencyProfile: number[];
  fftMagnitudes: number[];
  backgroundNoiseLevel: number;
  consistencyScore: number;
  peakFrequency: number;
  spectralCentroid: number;
  normalizedSpectralCentroid: number;
  downloadURL?: string;
  analyzedFeatures?: any;
  detectedSound?: string;
  
  sustainedHighAmplitudeDuration: number;
  lowFrequencyEnergyRatio: number;
  highFrequencyEnergyRatio: number;
  amplitudeVariation: number;
}

// Add this interface alongside UnifiedSoundProfile
export interface AudioFeatures {
    peakFrequency: number;
    consistencyScore: number;
    averageAmplitude: number;
    lowFrequencyEnergyRatio: number;
    spectralRolloff: number;
    duration: number;
    // Add any other features you're extracting in your audio analysis
}