import { Component, Input, OnChanges, SimpleChanges, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

interface NoteData {
  top: string;
  left: string;
  size: string;
  delay: string;
  duration: string;
}

@Component({
  selector: 'app-music-overlay',
  templateUrl: './music-overlay.component.html',
  styleUrls: ['./music-overlay.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class MusicOverlayComponent implements OnChanges, AfterViewInit {
  @Input() isAnimating: boolean = false;
  @Input() audioLevel: number = 0;

  @ViewChild('microphoneCanvas', { static: false }) canvasRef!: ElementRef<HTMLCanvasElement>;

  notes: NoteData[] = [];
  private readonly MAX_NOTES = 20;
  private readonly MIN_DURATION = 3;
  private readonly MAX_DURATION = 6;

  private audioContext!: AudioContext;
  private analyser!: AnalyserNode;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['audioLevel']) {
      this.updateNotes();
    }
  }

  ngAfterViewInit() {
    this.startMicrophoneWave();
  }

  private updateNotes() {
    const targetCount = Math.min(Math.floor(this.audioLevel * 20) + 5, this.MAX_NOTES);

    while (this.notes.length < targetCount) {
      this.notes.push(this.createNoteData());
    }
    while (this.notes.length > targetCount) {
      this.notes.pop();
    }

    this.notes.forEach(note => {
      note.duration = `${this.MIN_DURATION + (1 - this.audioLevel) * (this.MAX_DURATION - this.MIN_DURATION)}s`;
    });
  }

  private createNoteData(): NoteData {
    return {
      top: `${Math.random() * 100}%`,
      left: '-50px',
      size: `${Math.random() * 1 + 1}rem`,
      delay: `-${Math.random() * this.MAX_DURATION}s`,
      duration: `${this.MIN_DURATION + Math.random() * (this.MAX_DURATION - this.MIN_DURATION)}s`
    };
  }

  private startMicrophoneWave() {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(stream => {
        this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const audioInput = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        audioInput.connect(this.analyser);

        this.drawSpectrum();
      })
      .catch(err => {
        console.error('Error accessing microphone:', err);
      });
  }

  private drawSpectrum() {
    if (!this.canvasRef) {
      console.error('Canvas element not found');
      return;
    }

    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d')!;
    const cwidth = canvas.width;
    const cheight = canvas.height;
    const meterWidth = 8;
    const gap = 2;
    const meterNum = Math.floor(cwidth / (meterWidth + gap));

    const gradient = ctx.createLinearGradient(0, 0, 0, cheight);
    gradient.addColorStop(1, '#a467af');
    gradient.addColorStop(0.3, '#ff0');
    gradient.addColorStop(0, '#f00');
    ctx.fillStyle = gradient;

    const drawMeter = () => {
      const array = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.getByteFrequencyData(array);

      const step = Math.round(array.length / meterNum);
      ctx.clearRect(0, 0, cwidth, cheight);

      for (let i = 0; i < meterNum; i++) {
        const value = array[i * step];
        ctx.fillRect(i * (meterWidth + gap), cheight - value, meterWidth, value);
      }

      for (let i = 0; i < meterNum; i++) {
        const value = array[i * step];
        ctx.save();
        ctx.translate(cwidth, cheight - i * (meterWidth + gap));
        ctx.rotate(-Math.PI / 2);
        ctx.fillRect(0, 0, meterWidth, value);
        ctx.restore();
      }

      requestAnimationFrame(drawMeter);
    };

    requestAnimationFrame(drawMeter);
  }
}