<canvas #twinkleCanvas class="twinkle-canvas"></canvas>
<div class="position-relative h-100 w-100">
  <div class="pinwheel-container" [style.transform]="shakeEffect()">
    <div class="pinwheel-scale-wrapper" [style.transform]="pinwheelScaleTransform()"
      [style.opacity]="pinwheelOpacity()">
      <div #pinwheel class="pinwheel" [style.transform]="pinwheelTransform()">
        <div class="pinwheel-segment" *ngFor="let color of segmentColors(); let i = index">
          <div class="segment-content" [style.background-color]="color"></div>
        </div>
      </div>
    </div>
  </div>
</div>