import { Injectable, inject, signal } from '@angular/core';
import { Firestore, collection, addDoc, getDocs, query, orderBy, Timestamp, limit, writeBatch, doc } from '@angular/fire/firestore';
import { AudioLibraryVersion } from '@models/audio-library-version.model';
import { DeviceSettingsService } from './device-settings.service';
import { SoundProfileService } from './sound-profile.service';
import { Auth } from '@angular/fire/auth';

@Injectable({
    providedIn: 'root'
})
export class AudioLibraryVersionsService {
    private firestore = inject(Firestore);
    private deviceSettingsService = inject(DeviceSettingsService);
    private soundProfileService = inject(SoundProfileService);
    private auth = inject(Auth);
    private currentVersion = signal<AudioLibraryVersion | null>(null);

    private readonly VERSIONS_COLLECTION = 'audio_library_versions';

    async saveCurrentVersion(similaritySettings: {
        requiredMatches: number;
        minimumScore: number;
    }): Promise<string> {
        const currentUser = this.auth.currentUser;
        if (!currentUser) throw new Error('No authenticated user');

        // Gather all current settings
        const deviceSettings = await this.deviceSettingsService.getAllDeviceSettings();
        const soundProfiles = await this.soundProfileService.getAllProfiles();

        const versionData: AudioLibraryVersion = {
            uid: `v1-${Date.now()}`,
            date: new Date(),
            deviceSettings,
            soundProfiles,
            similaritySettings,
            createdBy: currentUser.uid
        };

        // Save to Firestore
        const docRef = await addDoc(collection(this.firestore, this.VERSIONS_COLLECTION), {
            ...versionData,
            date: Timestamp.fromDate(versionData.date)
        });

        return docRef.id;
    }

    async getVersions(): Promise<AudioLibraryVersion[]> {
        const versionsRef = collection(this.firestore, this.VERSIONS_COLLECTION);
        const q = query(versionsRef, orderBy('date', 'desc'));
        const snapshot = await getDocs(q);

        return snapshot.docs.map(doc => {
            const data = doc.data();
            return {
                ...data,
                date: (data['date'] as Timestamp).toDate()
            } as AudioLibraryVersion;
        });
    }

    getCurrentUserId(): string {
        const currentUser = this.auth.currentUser;
        if (!currentUser) throw new Error('No authenticated user');
        return currentUser.uid;
    }

    async getCurrentVersion(): Promise<AudioLibraryVersion | null> {
        try {
            const versionsRef = collection(this.firestore, 'audio_library_versions');
            const q = query(versionsRef, orderBy('date', 'desc'), limit(1));
            const snapshot = await getDocs(q);
            
            if (!snapshot.empty) {
                const doc = snapshot.docs[0];
                const data = doc.data() as AudioLibraryVersion;
                // Ensure date is properly converted from Firestore Timestamp
                data.date = (data.date as unknown as Timestamp).toDate();
                this.currentVersion.set(data);
                return data;
            }
            
            return null;
        } catch (error) {
            console.error('[AudioLibraryVersionsService] Error getting current version:', error);
            return null;
        }
    }

    async deleteVersion(versionId: string): Promise<void> {
        const db = inject(Firestore);
        
        // Start a batch operation
        const batch = writeBatch(db);
        
        // Delete the version document
        const versionRef = doc(db, 'audio_library_versions', versionId);
        batch.delete(versionRef);
        
        // Delete any associated files or sub-collections if needed
        // Add more batch operations here if needed
        
        // Commit the batch
        await batch.commit();
    }
} 