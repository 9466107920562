<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xl mx-auto">
  <div class="flex justify-between items-center my-4 sm:my-6 lg:my-8">
    <h3 class="text-xl sm:text-2xl lg:text-4xl font-semibold text-secondary">Videos</h3>
    <div class="dropdown dropdown-end">
      <div tabindex="0" role="button" class="btn btn-ghost btn-circle">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 110-2 1 1 0 010 2zm7 0a1 1 0 110-2 1 1 0 010 2zm7 0a1 1 0 110-2 1 1 0 010 2z" />
        </svg>
      </div>
      <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
        @if (isSuperAdmin()) {
          <li><a (click)="fixUploaderDetails()">Fix Uploader Details for Videos</a></li>
        }
      </ul>
    </div>
  </div>

  <div role="tablist" class="tabs tabs-bordered mb-4 flex justify-between">
    <div class="inline-flex">
      <a role="tab" class="tab" [ngClass]="{'tab-active': isTabActive('submitted')}" (click)="setTab('submitted')">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-green-500 mr-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M5 12l5 5l10 -10" />
        </svg>
        Submitted
        <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ getVideoCount('submitted') }}</span>
      </a>
      <a role="tab" class="tab" [ngClass]="{'tab-active': isTabActive('archived')}" (click)="setTab('archived')">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-gray-500 mr-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="3" y="4" width="18" height="4" rx="2" />
          <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
          <line x1="10" y1="12" x2="14" y2="12" />
        </svg>
        Archived
        <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ getVideoCount('archived') }}</span>
      </a>
      <a role="tab" class="tab" [ngClass]="{'tab-active': isTabActive('diagnosed')}" (click)="setTab('diagnosed')">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-blue-500 mr-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M3 21l18 0" />
          <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
          <line x1="5" y1="21" x2="5" y2="10.85" />
          <line x1="19" y1="21" x2="19" y2="10.85" />
          <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
        </svg>
        Diagnosed
        <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ getVideoCount('diagnosed') }}</span>
      </a>
    </div>
    <div class="inline-flex">
      @if (isSuperAdmin()) {
        <a role="tab" class="tab" [ngClass]="{'tab-active': isTabActive('draft')}" (click)="setTab('draft')">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-yellow-500 mr-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
            <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
          </svg>
          Draft
          <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ getVideoCount('draft') }}</span>
        </a>
      }
    </div>
  </div>

  
    @if (filteredVideos().length > 0) {
      <div class="overflow-x-auto overflow-y-auto">
      <table class="table overflow-x-auto overflow-y-auto">
        <thead>
          <tr>
            <th>Uploader Details</th>
            <th>Patient Name</th>
            <th>Date of Birth</th>
            <th>Description</th>
            <th>Status</th>
            <th class="cursor-pointer hover:bg-base-200" (click)="toggleSort()">
              <div class="flex items-center">
                Created At
                <svg xmlns="http://www.w3.org/2000/svg" 
                     class="icon icon-tabler icon-tabler-arrow-down ml-1" 
                     [class.rotate-180]="sortDirection() === 'asc'"
                     width="12" 
                     height="12" 
                     viewBox="0 0 24 24" 
                     stroke-width="2" 
                     stroke="currentColor" 
                     fill="none" 
                     stroke-linecap="round" 
                     stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <line x1="18" y1="13" x2="12" y2="19" />
                  <line x1="6" y1="13" x2="12" y2="19" />
                </svg>
              </div>
            </th>
            <th>Priority Score</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          @for (video of filteredVideos(); track video.id) {
            <tr>
              <td class="align-top">
                <div class="font-medium">{{ video.uploader || '-' }}</div>
                <div class="text-xs opacity-60">{{ video.uploaderEmail || '-' }}</div>
                <div class="text-xs opacity-60">{{ video.uploaderOccupation || '-' }}</div>
                <div class="text-xs opacity-60">{{ video.uploaderOrganization || '-' }}</div>
              </td>
              <td class="align-top">{{ video.patientFirstName }} {{ video.patientLastName }}</td>
              <td class="align-top">
                @if (video.patientDateOfBirth) {
                  {{ video.patientDateOfBirth | date:'d MMM yyyy' }}
                } @else {
                  -
                }
              </td>
              <td class="align-top">{{ video.description }}</td>
              <td class="align-top">
                @if (video.status === 'draft') {
                  <div class="badge badge-warning badge-outline">Draft</div>
                } @else if (video.status === 'submitted') {
                  <div class="badge badge-success badge-outline">Submitted</div>
                } @else if (video.status === 'archived') {
                  <div class="badge badge-neutral badge-outline">Archived</div>
                } @else if (video.status === 'diagnosed') {
                  <div class="badge badge-info badge-outline">Diagnosed</div>
                } @else if (video.status === 'deleted') {
                  <div class="badge badge-error badge-outline">Deleted</div>
                } @else {
                  <div class="badge badge-ghost badge-outline">{{ video.status }}</div>
                }
              </td>
              <td class="align-top">{{ video.createdAt | formatDate }}</td>
              <td class="align-top">{{ video.priorityScore }}</td>
            
              <td class="align-top">
                <div class="dropdown dropdown-end">
                  <label tabindex="0" class="btn btn-ghost btn-sm m-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-dots-vertical" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                      <path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                      <path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                    </svg>
                  </label>
                  <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                    <li><a (click)="viewVideo(video)">View</a></li>
                    <li><a (click)="updateVideoStatus(video, 'archived')">Archive</a></li>
                    <li><a (click)="deleteVideo(video)" class="text-error">Delete</a></li>
                  </ul>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    } @else {
      <div role="alert" class="alert">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="stroke-info h-6 w-6 shrink-0">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <span>No videos available for the selected status.</span>            
      </div>
    }
  
</section>

<div class="drawer drawer-end">
  <input id="view-video-drawer" type="checkbox" class="drawer-toggle" [checked]="isViewVideoModalOpen()" (change)="onDrawerChange($event)"/>
  <div class="drawer-side z-10">
    <label for="view-video-drawer" aria-label="close sidebar" class="drawer-overlay"></label>
    <div class="bg-base-300 text-base-content w-[95vw] sm:w-[800px] max-w-[1024px] flex flex-col h-screen">
      <div class="overflow-y-auto flex-grow">
        <app-video-details
          [editingVideo]="editingVideo()"
          [readOnly]="true"
          (closeModalEvent)="closeDrawer()"
          (videoSaved)="onVideoSaved()"
          (videoDraftSaved)="onVideoDraftSaved()"
        ></app-video-details>
      </div>
    </div>
  </div>
</div>