<div class="p-4 space-y-6">
    <div class="text-xl font-bold border-b pb-4 flex justify-between items-center">
        Audio Library Versions
        @if (currentVersionUid) {
            <span class="text-sm font-normal">
                Current Version: {{ currentVersionUid }}
            </span>
        }
    </div>

    <div class="flex justify-center">
        <button class="btn btn-primary w-full max-w-xs" (click)="saveCurrentVersion()" [disabled]="isSaving">
            {{ isSaving ? 'Saving...' : 'Save Current Version' }}
        </button>
    </div>

    <div class="overflow-x-auto">
        <table class="table table-zebra w-full">
            <thead>
                <tr>
                    <th>Version Date</th>
                    <th>Version UID</th>
                    <th class="text-right">Actions</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let version of versions">
                    <tr>
                        <td>{{ version.date | date:'medium' }}</td>
                        <td class="font-mono text-sm">{{ version.uid }}</td>
                        <td class="text-right space-x-2">
                            <button class="btn btn-sm btn-outline" (click)="restoreVersion(version)" [disabled]="isRestoring">
                                <span *ngIf="!isRestoring">Restore</span>
                                <span *ngIf="isRestoring" class="loading loading-spinner"></span>
                            </button>
                            <button class="btn btn-sm btn-ghost btn-error" (click)="deleteVersion(version)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M4 7l16 0"></path>
                                    <path d="M10 11l0 6"></path>
                                    <path d="M14 11l0 6"></path>
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                </svg>
                            </button>
                            <button class="btn btn-sm" (click)="toggleDetails(version)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="version.showDetails">
                        <td colspan="3">
                            <div class="collapse-content bg-base-200 p-4 rounded-lg">
                                <div class="text-sm">
                                    <h4 class="font-semibold mb-2">Changes from Default:</h4>
                                    
                                    <!-- Similarity Settings -->
                                    <div class="mb-4">
                                        <h5 class="font-medium">Similarity Settings:</h5>
                                        <table class="table table-sm">
                                            <tr>
                                                <td>Required Matches:</td>
                                                <td>{{ version.similaritySettings.requiredMatches }}</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Score:</td>
                                                <td>{{ version.similaritySettings.minimumScore }}%</td>
                                            </tr>
                                        </table>
                                    </div>

                                    <!-- Sound Profiles -->
                                    <div class="mb-4">
                                        <h5 class="font-medium">Sound Profiles:</h5>
                                        <div class="text-xs">
                                            Total Profiles: {{ version.soundProfiles.length }}
                                        </div>
                                    </div>

                                    <!-- Device Settings -->
                                    <div>
                                        <h5 class="font-medium">Device Settings:</h5>
                                        <div class="text-xs">
                                            Configured Devices: {{ getDeviceCount(version.deviceSettings) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>