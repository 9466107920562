import { Injectable, signal, computed } from '@angular/core';
import { 
  Firestore, 
  collection, 
  getDocs, 
  doc as firestoreDoc,
  getDoc,
  updateDoc, 
  setDoc 
} from '@angular/fire/firestore';
import { DeviceType, DEVICE_TYPES } from '@shared/models/sound-profile.model';
import { DeviceSettings } from '@models/device-settings.model';
import { UnifiedSoundProfile } from '@shared/models/sound-profile.model';
import { Observable } from 'rxjs';

const DEBUG_MODE = false; // Set to false in production


export const DEFAULT_DEVICE_SETTINGS: Record<DeviceType, DeviceSettings> = {
  'desktop + microphone': {
    deviceType: 'desktop + microphone',
    lowFrequencyThreshold: 400,
    frequencyTolerance: 300,
    consistencyTolerance: 0,
    lowFrequencyEnergyRatio: 0.75,
    amplitudeThreshold: 0.05,
    detectionThreshold: 0.6,
    requiredMatches: 1,
    minimumScore: 70,
    sensitivity: 0.5,
    soundStabilityDuration: 500
  },
  'desktop + webcam': {
    deviceType: 'desktop + webcam',
    detectionThreshold: 0.6,
    lowFrequencyThreshold: 450,
    lowFrequencyEnergyRatio: 0.31,
    consistencyTolerance: 0.68,
    frequencyTolerance: 300,
    amplitudeThreshold: 0.05,
    requiredMatches: 1,
    minimumScore: 70,
    sensitivity: 0.5,
    soundStabilityDuration: 500
  },
  'mobile': {
    deviceType: 'mobile',
    detectionThreshold: 0.6,
    lowFrequencyThreshold: 400,
    frequencyTolerance: 300,
    amplitudeThreshold: 0.05,
    consistencyTolerance: 0.4,
    lowFrequencyEnergyRatio: 0.4,
    requiredMatches: 1,
    minimumScore: 70,
    sensitivity: 0.5,
    soundStabilityDuration: 500
  },
  'mobile + headphone': {
    deviceType: 'mobile + headphone',
    detectionThreshold: 0.6,
    lowFrequencyThreshold: 400,
    lowFrequencyEnergyRatio: 0.25,
    consistencyTolerance: 0.3,
    frequencyTolerance: 300,
    amplitudeThreshold: 0.05,
    requiredMatches: 1,
    minimumScore: 70,
    sensitivity: 0.5,
    soundStabilityDuration: 500
  },
  'open mic': {
    deviceType: 'open mic',
    detectionThreshold: 0.7,
    lowFrequencyThreshold: 400,
    lowFrequencyEnergyRatio: 0.5,
    consistencyTolerance: 0.5,
    frequencyTolerance: 300,
    amplitudeThreshold: 0.15,
    requiredMatches: 1,
    minimumScore: 70,
    sensitivity: 0.5,
    soundStabilityDuration: 500
  }
};

@Injectable({
  providedIn: 'root'
})
export class DeviceSettingsService {
  private deviceSettingsState = signal<Record<DeviceType, DeviceSettings>>({ ...DEFAULT_DEVICE_SETTINGS });
  
  // Public getter for the signal
  public deviceSettingsSignal = computed(() => this.deviceSettingsState());
  
  constructor(private firestore: Firestore) {
    this.initializeDeviceSettings();
  }

  private async initializeDeviceSettings() {
    try {
      const settings = await this.getAllDeviceSettings();
      this.deviceSettingsState.set(settings);
    } catch (error) {
      console.error('[DeviceSettingsService] Error loading device settings:', error);
    }
  }

  async getDeviceSettings(deviceType: DeviceType): Promise<DeviceSettings> {
    const path = `config/soundSettings/deviceSettings/${deviceType}`;
    console.log('[DeviceSettingsService] Loading from path:', path);
    
    const docRef = firestoreDoc(this.firestore, path);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data() as DeviceSettings;
      return {
        ...DEFAULT_DEVICE_SETTINGS[deviceType],
        ...data
      };
    }
    
    return DEFAULT_DEVICE_SETTINGS[deviceType];
  }

  async saveDeviceSettings(settings: DeviceSettings): Promise<void> {
    const path = `config/soundSettings/deviceSettings/${settings.deviceType}`;
    console.log('[DeviceSettingsService] Saving to path:', path);
    
    const docRef = firestoreDoc(this.firestore, path);
    await setDoc(docRef, settings, { merge: true });
    
    // Update local signal
    this.deviceSettingsState.update(current => ({
      ...current,
      [settings.deviceType]: settings
    }));
  }

  async resetToDefaults(deviceType: DeviceType): Promise<DeviceSettings> {
    const defaultSettings = DEFAULT_DEVICE_SETTINGS[deviceType];
    const path = `config/soundSettings/deviceSettings/${deviceType}`;
    
    try {
      const docRef = firestoreDoc(this.firestore, path);
      await setDoc(docRef, defaultSettings);
      
      // Update signal with default settings
      this.deviceSettingsState.update(current => ({
        ...current,
        [deviceType]: defaultSettings
      }));
      
      return defaultSettings;
    } catch (error) {
      console.error(`Error resetting device settings for ${deviceType}:`, error);
      throw error;
    }
  }

  async getAllDeviceSettings(): Promise<Record<DeviceType, DeviceSettings>> {
    const settings: Record<DeviceType, DeviceSettings> = { ...DEFAULT_DEVICE_SETTINGS };
    
    try {
      const collectionRef = collection(this.firestore, 'config/soundSettings/deviceSettings');
      const snapshot = await getDocs(collectionRef);
      
      snapshot.forEach(doc => {
        const deviceType = doc.id as DeviceType;
        const data = doc.data() as DeviceSettings;
        settings[deviceType] = {
          ...DEFAULT_DEVICE_SETTINGS[deviceType],
          ...data
        };
      });
    } catch (error) {
      console.error('[DeviceSettingsService] Error loading device settings:', error);
    }
    
    return settings;
  }

  testDeviceTypeProfile(
    profile: UnifiedSoundProfile, 
    deviceType: DeviceType
  ): Observable<{ 
    success: boolean, 
    details?: any 
  }> {
    return new Observable(observer => {
      // Validate profile and device type
      if (!profile || !deviceType) {
        observer.error(new Error('Invalid profile or device type'));
        return;
      }

      // Simulate device type profile testing
      // This could involve:
      // 1. Checking profile compatibility
      // 2. Verifying detection thresholds
      // 3. Running a quick diagnostic
      const testResult = this.validateDeviceTypeProfile(profile, deviceType);

      // Emit test result
      observer.next(testResult);
      observer.complete();
    });
  }

  private validateDeviceTypeProfile(
    profile: UnifiedSoundProfile, 
    deviceType: DeviceType
  ): { success: boolean, details?: any } {
    try {
      // Perform validation checks
      const checks = [
        this.checkProfileFrequency(profile),
        this.checkProfileAmplitude(profile),
        this.checkDeviceTypeCompatibility(profile, deviceType)
      ];

      // All checks must pass
      const success = checks.every(check => check.passed);

      return {
        success,
        details: {
          checks,
          profile,
          deviceType
        }
      };
    } catch (error: unknown) {
      // Use type guard to handle unknown error type
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'An unknown error occurred';

      console.error('Device type profile validation error:', errorMessage);
      return { 
        success: false, 
        details: { error: errorMessage } 
      };
    }
  }

  private checkProfileFrequency(profile: UnifiedSoundProfile) {
    // Example frequency validation
    const isValid = profile.dominantFrequency > 100 && profile.dominantFrequency < 500;
    return {
      type: 'frequency',
      passed: isValid,
      value: profile.dominantFrequency
    };
  }

  private checkProfileAmplitude(profile: UnifiedSoundProfile) {
    // Use peakAmplitude if averageAmplitude is not available
    const amplitudeToCheck = profile.averageAmplitude || profile.peakAmplitude;
    
    // Example amplitude validation
    const isValid = amplitudeToCheck > 0.1 && amplitudeToCheck < 0.9;
    
    return {
      type: 'amplitude',
      passed: isValid,
      value: amplitudeToCheck
    };
  }

  private checkDeviceTypeCompatibility(
    profile: UnifiedSoundProfile, 
    deviceType: DeviceType
  ) {
    // Example device type compatibility check
    const compatibleTypes = ['desktop + webcam', 'mobile'];
    const isCompatible = compatibleTypes.includes(deviceType);
    
    return {
      type: 'deviceTypeCompatibility',
      passed: isCompatible,
      value: deviceType
    };
  }

  async restoreDeviceSettings(settings: Record<string, DeviceSettings>) {
    try {
      // Save each device type's settings
      for (const [deviceType, deviceSettings] of Object.entries(settings)) {
        await this.saveDeviceSettings({
          ...deviceSettings,
          deviceType: deviceType as DeviceType
        });
      }
      console.log('📱 Device settings restored successfully');
    } catch (error) {
      console.error('Error restoring device settings:', error);
      throw error;
    }
  }

  async loadDeviceSettings(deviceType: DeviceType): Promise<DeviceSettings | null> {
    try {
      if (DEBUG_MODE) {
        console.log('[DeviceSettingsService] Loading from path:', `config/soundSettings/deviceSettings/${deviceType}`);
      }
      
      const docRef = firestoreDoc(this.firestore, `config/soundSettings/deviceSettings/${deviceType}`);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        return docSnap.data() as DeviceSettings;
      } else {
        // Return default settings if no custom settings exist
        return DEFAULT_DEVICE_SETTINGS[deviceType];
      }
    } catch (error) {
      console.error('Error loading device settings:', error);
      return DEFAULT_DEVICE_SETTINGS[deviceType];
    }
  }
} 