import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore } from '@angular/fire/firestore';
import { provideAuth } from '@angular/fire/auth';
import { ReactiveFormsModule } from '@angular/forms';
import { provideStorage } from '@angular/fire/storage';
import { provideHttpClient } from '@angular/common/http';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { NGX_ECHARTS_CONFIG } from 'ngx-echarts';

import { app, getFirestoreInstance, getAuthInstance, getStorageInstance } from './shared/firebase-config';
import { routes } from './app-routing.module';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideFirebaseApp(() => app),
    provideFirestore(() => getFirestoreInstance()),
    provideAuth(() => getAuthInstance()),
    provideStorage(() => getStorageInstance()),
    ReactiveFormsModule,
    provideHttpClient(),
    provideCharts(withDefaultRegisterables()),
    {
      provide: NGX_ECHARTS_CONFIG,
      useValue: {
        echarts: () => import('echarts')
      }
    }
  ]
};