<div class="bubble-container">
  <div class="debug-panel">
    <div class="debug-item">
      Low:
      <span class="value" id="lowFreq">0</span>
    </div>
    <div class="debug-item">
      Mid:
      <span class="value" id="midFreq">0</span>
    </div>
    <div class="debug-item">
      High:
      <span class="value" id="highFreq">0</span>
    </div>
  </div>

  <!-- Three.js canvas will be rendered here -->
  <div id="threejs-container"></div>
</div>