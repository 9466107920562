import { Component, AfterViewInit, OnDestroy, Input } from '@angular/core';
import * as THREE from 'three';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-bubble-overlay',
  templateUrl: './bubble-overlay.component.html',
  styleUrls: ['./bubble-overlay.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class BubbleOverlayComponent implements AfterViewInit, OnDestroy {
  @Input() isAnimating: boolean = false;

  private scene!: THREE.Scene;
  private camera!: THREE.PerspectiveCamera;
  private renderer!: THREE.WebGLRenderer;
  private particles!: THREE.Points;
  private audioContext!: AudioContext;
  private analyser!: AnalyserNode;
  private dataArray!: Uint8Array;
  private isAudioInitialized = false;
  private uniforms: any;

  ngAfterViewInit() {
    this.initThreeJS();
    this.initAudio();
  }

  ngOnDestroy() {
    if (this.renderer) {
      this.renderer.dispose();
    }
  }

  private initThreeJS() {
    const container = document.getElementById('threejs-container')!;
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color('#222');

    this.camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 5000);
    this.camera.position.z = 1;

    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(this.renderer.domElement);

    this.uniforms = {
      uTime: { value: 0 },
      uAudioLow: { value: 0 },
      uAudioMid: { value: 0 },
      uAudioHigh: { value: 0 },
    };

    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const particleCount = 5500;

    for (let i = 0; i < particleCount; i++) {
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos(2 * Math.random() - 1);
      const r = Math.cbrt(Math.random()) * 2;
      positions.push(
        r * Math.sin(phi) * Math.cos(theta),
        r * Math.sin(phi) * Math.sin(theta),
        r * Math.cos(phi)
      );
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('normal', new THREE.Float32BufferAttribute(positions, 3));

    const material = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: `
        uniform float uTime;
        uniform float uAudioLow;
        uniform float uAudioMid;
        uniform float uAudioHigh;

        varying vec3 vColor;
        varying float vAudioMid;

        void main() {
          vec3 displaced = position + normal * sin(uTime + position.x * 10.0) * 0.1;
          vColor = vec3(0.5 + 0.5 * sin(uTime), 0.5 + 0.5 * cos(uTime), 0.5);
          vAudioMid = uAudioMid;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(displaced, 1.0);
          gl_PointSize = 5.0;
        }
      `,
      fragmentShader: `
        varying vec3 vColor;
        varying float vAudioMid;

        void main() {
          gl_FragColor = vec4(vColor * vAudioMid, 1.0);
        }
      `,
      transparent: true,
      depthWrite: false,
    });

    this.particles = new THREE.Points(geometry, material);
    this.scene.add(this.particles);

    this.animate();
  }

  private animate() {
    requestAnimationFrame(() => this.animate());
    this.uniforms.uTime.value = performance.now() * 0.001;

    if (this.isAudioInitialized) {
      this.analyser.getByteFrequencyData(this.dataArray);

      const low = this.getAverageFrequencyRange(0, 20) * 0.7;
      const mid = this.getAverageFrequencyRange(20, 50) * 0.7;
      const high = this.getAverageFrequencyRange(50, 100) * 0.7;

      this.uniforms.uAudioLow.value = low;
      this.uniforms.uAudioMid.value = mid;
      this.uniforms.uAudioHigh.value = high;

      document.getElementById('lowFreq')!.textContent = low.toFixed(3);
      document.getElementById('midFreq')!.textContent = mid.toFixed(3);
      document.getElementById('highFreq')!.textContent = high.toFixed(3);
    }

    this.renderer.render(this.scene, this.camera);
  }

  private getAverageFrequencyRange(start: number, end: number): number {
    let sum = 0;
    for (let i = start; i < end; i++) {
      sum += this.dataArray[i];
    }
    return sum / (end - start) / 255;
  }

  initAudio() {
    if (this.isAudioInitialized) return;

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const source = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 512;
        source.connect(this.analyser);
        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
        this.isAudioInitialized = true;
      })
      .catch(err => {
        console.error('Error accessing microphone:', err);
      });
  }
}