<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xxl mx-auto">
  <div class="flex justify-between items-center my-4 sm:my-6 lg:my-8">
    <h3 class="text-xl sm:text-2xl lg:text-4xl font-semibold text-secondary">Patient Data</h3>
  </div>

  <div class="overflow-x-auto">
    <div class="overflow-x-auto" style="height: 8px;"></div>
  </div>

  <div class="overflow-x-auto">
    <table class="table table-zebra w-full table-pin-rows table-pin-cols">
      <thead>
        <tr>
          @if (isAdmin()) {
            <th class="bg-base-200 cursor-pointer hover:bg-base-300" (click)="toggleSort('uploaderName')">
              <div class="flex items-center gap-2">
                Uploaded By
                @if (sortField() === 'uploaderName') {
                  <svg xmlns="http://www.w3.org/2000/svg" 
                       class="icon icon-tabler icon-tabler-chevron-{{ sortDirection() === 'asc' ? 'up' : 'down' }} inline-block" 
                       width="20" 
                       height="20" 
                       viewBox="0 0 24 24" 
                       stroke-width="2" 
                       stroke="currentColor" 
                       fill="none" 
                       stroke-linecap="round" 
                       stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <polyline [attr.points]="sortDirection() === 'asc' ? '6 15 12 9 18 15' : '6 9 12 15 18 9'"></polyline>
                  </svg>
                }
              </div>
            </th>
            <th class="bg-base-200 cursor-pointer hover:bg-base-300" (click)="toggleSort('organization')">
              <div class="flex items-center gap-2">
                Organization
                @if (sortField() === 'organization') {
                  <svg xmlns="http://www.w3.org/2000/svg" 
                       class="icon icon-tabler icon-tabler-chevron-{{ sortDirection() === 'asc' ? 'up' : 'down' }} inline-block" 
                       width="20" 
                       height="20" 
                       viewBox="0 0 24 24" 
                       stroke-width="2" 
                       stroke="currentColor" 
                       fill="none" 
                       stroke-linecap="round" 
                       stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <polyline [attr.points]="sortDirection() === 'asc' ? '6 15 12 9 18 15' : '6 9 12 15 18 9'"></polyline>
                  </svg>
                }
              </div>
            </th>
            <th class="bg-base-200 cursor-pointer hover:bg-base-300" (click)="toggleSort('occupation')">
              <div class="flex items-center gap-2">
                Occupation
                @if (sortField() === 'occupation') {
                  <svg xmlns="http://www.w3.org/2000/svg" 
                       class="icon icon-tabler icon-tabler-chevron-{{ sortDirection() === 'asc' ? 'up' : 'down' }} inline-block" 
                       width="20" 
                       height="20" 
                       viewBox="0 0 24 24" 
                       stroke-width="2" 
                       stroke="currentColor" 
                       fill="none" 
                       stroke-linecap="round" 
                       stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <polyline [attr.points]="sortDirection() === 'asc' ? '6 15 12 9 18 15' : '6 9 12 15 18 9'"></polyline>
                  </svg>
                }
              </div>
            </th>
          }
          <th>Patient Name</th>
          <th>Date of Birth</th>
          <th class="cursor-pointer hover:bg-base-300" (click)="toggleSort('createdAt')">
            <div class="flex items-center gap-2">
              Date of Recording
              @if (sortField() === 'createdAt') {
                <svg xmlns="http://www.w3.org/2000/svg" 
                     class="icon icon-tabler icon-tabler-chevron-{{ sortDirection() === 'asc' ? 'up' : 'down' }} inline-block" 
                     width="20" 
                     height="20" 
                     viewBox="0 0 24 24" 
                     stroke-width="2" 
                     stroke="currentColor" 
                     fill="none" 
                     stroke-linecap="round" 
                     stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <polyline [attr.points]="sortDirection() === 'asc' ? '6 15 12 9 18 15' : '6 9 12 15 18 9'"></polyline>
                </svg>
              }
            </div>
          </th>
          @if (patientDataFields.eventDuration) {
            <th>Event Duration</th>
          }
          @if (patientDataFields.frequency) {
            <th>Frequency</th>
          }
          @if (patientDataFields.duration) {
            <th>Duration</th>
          }
          @if (patientDataFields.epilepsy) {
            <th>Epilepsy</th>
          }
          @if (patientDataFields.medication) {
            <th>Medication</th>
          }
          @if (patientDataFields.seizureOccurrence) {
            <th>Seizure Occurrence</th>
          }
          @if (patientDataFields.additionalComments) {
            <th>Additional Comments</th>
          }
          <th class="!sticky !right-0 bg-base-200">Action</th>
        </tr>
      </thead>
      <tbody>
        @for (video of videos(); track video.id) {
          <tr>
            @if (isAdmin()) {
              <td class="bg-base-200">
                <div class="font-medium">{{ video.uploaderDetails?.displayName || '-' }}</div>
                <div class="text-xs opacity-60">{{ video.uploaderDetails?.email || '-' }}</div>
              </td>
              <td class="bg-base-200">{{ video.uploaderDetails?.organizationName || '-' }}</td>
              <td class="bg-base-200">{{ video.uploaderDetails?.occupation || '-' }}</td>
            }
            <td>{{ video.patientFirstName }} {{ video.patientLastName }}</td>
            <td>{{ video.patientDateOfBirth | date:'mediumDate' }}</td>
            <td>{{ video.createdAt | formatDate }}</td>
            @if (patientDataFields.eventDuration) {
              <td>{{ video.additionalInfo.eventDuration || '-' }}</td>
            }
            @if (patientDataFields.frequency) {
              <td>{{ video.additionalInfo.frequency || '-' }}</td>
            }
            @if (patientDataFields.duration) {
              <td>{{ video.additionalInfo.duration || '-' }}</td>
            }
            @if (patientDataFields.epilepsy) {
              <td>{{ video.additionalInfo.epilepsy || '-' }}</td>
            }
            @if (patientDataFields.medication) {
              <td>{{ video.additionalInfo.medication || '-' }}</td>
            }
            @if (patientDataFields.seizureOccurrence) {
              <td>{{ video.additionalInfo.seizureOccurrence || '-' }}</td>
            }
            @if (patientDataFields.additionalComments) {
              <td>{{ video.additionalInfo.additionalComments || '-' }}</td>
            }
            <td class="!sticky !right-0 bg-base-100">
              <button (click)="viewVideo(video)" class="btn btn-xs btn-outline">View</button>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</section>